import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { TeaLogService } from '../tealog.service';
import { IContentDownloadMetadata } from '../tealog.model';

/**
 * @whatItDoes Enters a Tealium log record of a content download event generated by a click of an anchor tag or a button.
 *
 * @howToUse
 * ```
 *      In Anchor tag, both contentTitle and contentUrl fields are optional 
 *     <a href="location/of/the/file" target="_blank" teaLogContentDownload>Download File</a>
 *     <a href="location/of/the/file" target="_blank" [teaLogContentDownload]="{contentTitle: '<Name of The File>', contentUrl: '<location/of/the/file>'}>Download File</a>
 * 
 *      In Anchor tag, both contentTitle is optional and contentUrl is required
 *     <button type="button" [teaLogContentDownload]="{contentUrl: '<location/of/the/file>'}">Download File</button>
 *     <button type="button" [teaLogContentDownload]="{contentTitle: '<Name of The File>', contentUrl: '<location/of/the/file>'}">Download File</button>
 * 
 * ```
 */
@Directive({
  selector: '[teaLogContentDownload]'
})
export class TeaLogContentDownloadDirective {
  
  private contentDownloadMetadata: IContentDownloadMetadata | any;

  constructor(
    private teaLogService: TeaLogService, 
    private elementRef: ElementRef<HTMLAnchorElement | HTMLButtonElement>){}
  
  @Input('teaLogContentDownload')
  set metadata(contentDownloadMetadata: IContentDownloadMetadata) {
    this.contentDownloadMetadata = contentDownloadMetadata;
  }
  @HostListener('click') onClick() {
    this.teaLogContentDownloadEvent();
  }

  private teaLogContentDownloadEvent(){
    if(this.elementRef.nativeElement instanceof HTMLButtonElement){
      this.teaLogService.teaLogContentDownloadAction(
        this.contentDownloadMetadata.contentTitle ? this.contentDownloadMetadata.contentTitle : this.elementRef.nativeElement.innerText, 
        this.contentDownloadMetadata.contentUrl ? this.contentDownloadMetadata.contentUrl: "");

    } else if(this.elementRef.nativeElement instanceof HTMLAnchorElement){
      this.teaLogService.teaLogContentDownloadAction(
        this.contentDownloadMetadata.contentTitle ? this.contentDownloadMetadata.contentTitle : this.elementRef.nativeElement.innerText, 
        this.contentDownloadMetadata.contentUrl ? this.contentDownloadMetadata.contentUrl : this.elementRef.nativeElement.href);
  
    }
  }
}
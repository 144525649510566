import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { TeaLogService } from '../tealog.service';

type contentBrowsingInput = {
  contentEntryTime: Date, 
  routeTitle: string, 
  pageType: string,
  origin: string
}
/**
 * @whatItDoes Enters a Tealium log record of a content browsing event generated by a click of an anchor tag or a button.
 *
 * @howToUse
 * ```
 *      In anchor tag, if routeTitle is not specified its value defaults to the innerText string of the anchor
 *     <a href="path/to/route" target="_blank" [teaLogContentBrowsing]="{pageType: '<Classification of the route>'}>Go To Other Component</a>
 *     <a href="path/to/route" target="_blank" [teaLogContentBrowsing]="{routeTitle: '<Title of The Route>', pageType: '<Classification of the route>'}>Go To Other Component</a>
 *     <a href="path/to/route" target="_blank" [teaLogContentBrowsing]="{routeTitle: '<Title of The Route>', pageType: '<Classification of the route>', origin: '<Route Title of the origin>'}>Go To Other Component</a>
 * 
 *      In button tag, if routeTitle is not specified its value defaults to the innerText string of the button
 *     <button type="button" [teaLogContentBrowsing]="{pageType: '<Classification of the route>'}" (click)="goToComponent()">Go To Other Component</button>
 *     <button type="button" [teaLogContentBrowsing]="{routeTitle: '<Title of The Route>', pageType: '<Classification of the route>'}" (click)="goToComponent()">Go To Other Component</button>
 *     <button type="button" [teaLogContentBrowsing]="{routeTitle: '<Title of The Route>', pageType: '<Classification of the route>', origin: '<Route Title of the origin>'}" (click)="goToComponent()">Go To Other Component</button>
 * 
 * ```
 */
@Directive({
  selector: '[teaLogContentBrowsing]'
})
export class TeaLogContentBrowsingDirective {
  
  private contentBrowsingMetadata: contentBrowsingInput | any;

  constructor(
    private teaLogService: TeaLogService, 
    private elementRef: ElementRef<HTMLAnchorElement | HTMLButtonElement>){}
  
  @Input('teaLogContentBrowsing')
  set metadata(contentBrowsingMetadata: contentBrowsingInput) {
    this.contentBrowsingMetadata = contentBrowsingMetadata;
  }
  @HostListener('click') onClick() {
    this.teaLogContentBrowsingEvent();
  }

  private teaLogContentBrowsingEvent(){
    if(this.elementRef.nativeElement instanceof HTMLButtonElement){
      this.teaLogService.teaLogContentBrowsingAction(
        this.contentBrowsingMetadata.contentEntryTime || new Date(), 
        this.contentBrowsingMetadata.routeTitle || this.elementRef.nativeElement.innerText,
        this.contentBrowsingMetadata.pageType,
        this.contentBrowsingMetadata.origin);

    } else if(this.elementRef.nativeElement instanceof HTMLAnchorElement){
      this.teaLogService.teaLogContentBrowsingAction(
        this.contentBrowsingMetadata.contentEntryTime || new Date(), 
        this.contentBrowsingMetadata.routeTitle || this.elementRef.nativeElement.innerText,
        this.contentBrowsingMetadata.pageType,
        this.contentBrowsingMetadata.origin);
    }
  }
}
import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { TeaLogService } from '../tealog.service';
import { IHyperlinkNavigationMetadata } from '../tealog.model';

/**
 * @whatItDoes Enters a Tealium log record of a navigation event generated by a click of a hyperlink in anchor tag.
 *
 * @howToUse
 * ```
 *     <a href="https://www.cisco.com" target="_blank" teaLogExtHyperlinkNavigation>Link To Cisco Home</a>
 *
 * ```
 */
@Directive({
  selector: '[teaLogExtHyperlinkNavigation]'
})
export class TeaLogExtHyperlinkNavigationDirective {
  
  private hyperlinkNavigationMetadata: IHyperlinkNavigationMetadata | any;

  constructor(
    private teaLogService: TeaLogService, 
    private elementRef: ElementRef){}
  
  @Input('teaLogExtHyperlinkNavigation')
  set metadata(hyperlinkNavigationMetadata: IHyperlinkNavigationMetadata) {
    this.hyperlinkNavigationMetadata = hyperlinkNavigationMetadata;
  }
  @HostListener('click') onClick() {
    this.teaLogHyperlinkEvent();
  }

  private teaLogHyperlinkEvent(){
    this.teaLogService.teaLogExternalHyperlinkAction(
      this.hyperlinkNavigationMetadata.linkText ? this.hyperlinkNavigationMetadata.linkText : this.elementRef.nativeElement.innerText, 
      this.hyperlinkNavigationMetadata.linkUrl ? this.hyperlinkNavigationMetadata.linkUrl : this.elementRef.nativeElement.href);
  }

  ngOnDestroy(): void {
  }

}
import { ScheduleConflictOperation, EventType, IContentDownloadMetadata, IContentSearchMetadata, IHyperlinkNavigationMetadata, IRouterNavigationMetadata, IScheduleConflictMetadata, ITeaLogData, ITeaLogUser, TeaLogUser, TypeUser, IScheduleManagementMetadata, ISessionBrowsingMetadata, ISpeakerBrowsingMetadata, IMyScheduleBrowsingMetadata, ICarouselActivityMetadata, ISurveyActivityMetadata, IButtonActivityMetadata, ScheduleSection, CarouselActivityOperation, SurveyActivityOperation, IContentBrowsingMetadata, ScheduleManagementOperation, IOktaJWT, RecommendationType, IRecommendationReceiptMetadata, TypeRecommendation, IPromptSubmissionMetadata, IRecommendationManagementMetadata, RecommendationManagementOperation, IInterestMetadata, InterestOperation, TypeInterest, InterestType, TeaLogConfigType, TypeSession, IPromptFeedbackMetadata, IBadgeScanMetadata, system, IExceptionOccurrence, IHeartBeatMetadata, defaultConfig, JWTToken } from './tealog.model';
import { legacyInitCDC, resolveAuth } from './tealog.library';
declare var cdc: any, trackEvent: any;

export class TeaLogUtil {

  private user: ITeaLogUser | any;
  private defaultUser: ITeaLogUser | any;
  private eventType: string;
  private navigator: any;
  private viewHistory: any = {}; 
  private config: any;
  private activeIntervals: any[];
  private heartBeatDelay = 5 * 60 * 1000; // 5 minutes (to milliseconds)
  private publisherAuthAOTDelay = 1; // minutes
  private publisherToken: JWTToken;

  constructor() {
    this.config = defaultConfig;
    this.attemptResolvingLocalProps();
    this.viewHistory = {};
    this.activeIntervals = [];
  }

  /**
   * Reset user configuration to system default, clearing any previously set user data.
   * 
   * @remarks Use for logouts, or configuration resets.
   *
   * @param eventId - Optionally set default event id.
   * @param resetConfig - Optionally reset configuration to system default when set to true;
   *
  */
  public reset(eventId?: string, resetConfig?: boolean): void{
    this.clearHistory();
    // this.clearIntervals();
    if(resetConfig){
      this.config = defaultConfig;
      this.attemptResolvingLocalProps();
    }
    if(eventId){
          system.eventId = eventId;
          this.defaultUser = new TeaLogUser(system);
          this.user = new TeaLogUser(system);
    }
  }

  public overrideConfiguration(customConfig: TeaLogConfigType): void {
    try{
      this.config = {
        logging: Object.assign(defaultConfig.logging, customConfig.logging), 
        settings: Object.assign(defaultConfig.settings, customConfig.settings), 
        data: Object.assign(defaultConfig.data, customConfig.data)
      };
    } catch (err) {
      this.logToConsole("error", `${this.config.logging.message.initError}: ${err}`);
    }
    this.attemptResolvingLocalProps();
  }

  /**
  * @deprecated The method should not be used, use overrideConfiguration instead
  */
  public overrideUserInfo(user: TypeUser): void {
    try{
      this.defaultUser = new TeaLogUser(user);
      this.user = new TeaLogUser(user);
      this.eventType = this.resolveEventType(user.eventId);
    } catch (err) {
      this.logToConsole("error", `${this.config.logging.message.initError}: ${err}`)
    }
  }

  /**
  * @deprecated The method should not be used, use overrideConfiguration instead
  */
  public hidePII(): void {
    if(this.user){
      this.user.hidePII();
    }
  }

  private attemptResolvingLocalProps(): void {
    try {
      if(this.config.data.user){
        this.defaultUser = new TeaLogUser(this.config.data.user);
        this.user = new TeaLogUser(this.config.data.user);
        this.eventType = this.resolveEventType(this.config.data.user.eventId);
      } else {
        this.defaultUser = new TeaLogUser(
          JSON.parse(sessionStorage.getItem(this.config.data.userSessionStorageKey) || "{}"));
        this.user = new TeaLogUser(
          JSON.parse(sessionStorage.getItem(this.config.data.userSessionStorageKey) || "{}"));
          this.eventType = this.resolveEventType(this.user.eventId);
      }
      if(this.config.settings.hidePII){
        this.user.hidePII();
      }
      this.navigator = this.resolveNavigator();
      let auth: IOktaJWT;
      if(this.config.data.jwt){
        auth = resolveAuth(this.config.data.jwt);
      } else{
        auth = resolveAuth(sessionStorage.getItem(this.config.data.jwtStorageKey));
      }
      this.defaultUser.accessLevel = auth?.access_level;
      this.user.accessLevel = auth?.access_level;
    } catch (err) {
      this.logToConsole("error", `${this.config.logging.message.initError}: ${err}`);
    }
  }

  /**
  * @deprecated The method should not be used, use teaLogContentBrowsingAction instead
  */
  public teaLogComponentLoad(routeTitle: string, pageType: string): void {
    try{
      this.teaLogRouterNavigation([{
        component: routeTitle,
        pageType: pageType,
        browsingMilliseconds: 0
      }]);
    } catch(e){
      this.logToConsole("error", e);
    }
  }

  /**
  * @deprecated The method should not be used, use teaLogContentBrowsingAction instead
  */
  public teaLogComponentTermination(componentInitTime: Date, routeTitle: string, pageType: string): void {
    try{
      this.teaLogRouterNavigation([{
        component: routeTitle,
        pageType: pageType,
        browsingMilliseconds: new Date().getTime() - componentInitTime.getTime()
      }]);
    } catch(e){
      this.logToConsole("error", e);
    }
  }

  /**
   * Trigger a Tealium logging event of type contentBrowsing.
   * 
   * @remarks Use for router navigation, or component loading/termination.
   *
   * @param contentEntryTime - Date-time of entry
   * @param routeTitle - Route title or Component title or Drawer title
   * @param pageType - Title of parent route, or logical topic of the component, i.e. 'Social', 'Compete', 'Learn'
   * @param origin - Origin Component or Route Title of the request
   *
  */
  public teaLogContentBrowsingAction(
    contentEntryTime: Date, routeTitle: string, pageType: string, origin?: string): void {
      try{
        this.teaLogContentBrowsing([{
          browsingMilliseconds: new Date().getTime() - contentEntryTime.getTime(),
          component: routeTitle,
          pageType: pageType,
          origin
        }]);
      } catch(e){
        this.logToConsole("error", e);
      }
  }

  /**
   * Trigger a Tealium logging event of type contentBrowsing with implicit recording and retrieval of previous entry time calculated based on viewId. 
   * 
   * @remarks Use for router navigation, or component loading/termination.
   *
   * @param viewId - Unique identifier of the view that is set or refreshed.
   * @param viewTitle - View title or Component title or Drawer title
   * @param pageType - Title of parent route, or logical topic of the component, i.e. 'Social', 'Compete', 'Learn'
   * @param origin - Origin Module or Route Title of the request
   *
  */
  public teaLogContentViewing(
    viewId: string, viewTitle: string, pageType: string, origin?: string): void {
      try{
        this.teaLogContentBrowsing(
          this.recordEntry(viewId, {
            component: viewTitle,
            pageType: pageType,
            origin
          }));
      } catch(e){
        this.logToConsole("error", e);
      }
  }

  /**
   * Trigger a Tealium logging event of type externalHyperlink.
   * 
   * @remarks Use for hyperlink navigation.
   *
   * @param linkText - Title of the link, or title of the button leading to external system.
   * @param linkUrl - URL of the link, or destination of the button leading to external system.
   * 
   */
  public teaLogExternalHyperlinkAction(linkText: string, linkUrl: string): void {
    try{
      this.teaLogHyperlinkNavigation([{
        linkText,
        linkUrl
      }]);
    } catch(e){
      this.logToConsole("error", e);
    }
  }

  /**
   * Trigger a Tealium logging event of type contentDownload.
   * 
   * @remarks Use for download of files and content.
   *
   * @param contentTitle - Title of the file, or title of the content.
   * @param contentUrl - URL of the link, or destination of the button leading to the file.
   * 
   */
  public teaLogContentDownloadAction(contentTitle: string, contentUrl: string): void {
    try{
      this.teaLogContentDownload([{
        contentTitle,
        contentUrl
      }]);
    } catch(e){
      this.logToConsole("error", e);
    }
  }

  /**
   * Trigger a Tealium logging event of type keywordSearch.
   * 
   * @remarks Use for searching and filtering actions.
   *
   * @param keyword - Keyword searched, filter applied to the content
   * @param dataset - Dataset the filter is applied onto, i.e. 'Session Data' or 'All Content' or 'Speaker Data' etc...
   * @param filters - Any additional optional filters applied to the dataset, i.e. [{'isTestRecord': yes}], null if none
   * 
   */
  public teaLogKeywordSearchAction(keyword: string, dataset?: string, filters?: string[]): void {
    try{
      this.teaLogContentSearch([{
        keyword,
        dataset,
        filters
      }]);
    } catch(e){
      this.logToConsole("error", e);
    }
  }
  
  /**
   * Trigger a Tealium logging event of type scheduleManagement.
   * 
   * @remarks Use for agenda or schedule operations such as enrollments, un-enrollments, calendar download, calendar sync, and invitation responses.
   *
   * @param operation - Performed operation.
   * @param targetSessionTitle - Title of the session added/removed/accepted. Leave blank for operations involving multiple sessions.
   * @param targetSessionType - Type of the session added/removed/accepted. Leave blank for operations involving multiple sessions.
   * @param targetSessionTimeId - Session Time ID of the session added/removed/accepted. Leave blank for operations involving multiple sessions.
   * @param isRecommended - Set true if for operations involving sessions recommended to the user.
   * @param targetTimeSlotStart - Optional start time of the interval. Use when operation involves personal time scheduling.
   * @param targetTimeSlotEnd - Optional end time of the interval. Use when operation involves personal time scheduling.
   * 
   */
  public teaLogScheduleManagementAction(
    operation: ScheduleManagementOperation,
    targetSessionTitle?: string,
    targetSessionType?: string,
    targetSessionTimeId?: string,
    isRecommended?: boolean,
    targetTimeSlotStart?: Date,
    targetTimeSlotEnd?: Date): void {
      try{
        this.teaLogScheduleManagement([{
          scheduleManagementOperation: ScheduleManagementOperation[operation],
          targetSessionTitle,
          targetSessionType,
          targetSessionTimeId,
          isRecommended,
          targetTimeSlotStart,
          targetTimeSlotEnd,
          conversationId: undefined
        }]);
      } catch(e){
        this.logToConsole("error", e);
      }
  }

  
  /**
   * Trigger multiple Tealium logging event of type scheduleManagement.
   * 
   * @remarks Use for any set of session recommendation issued by the AI assistant that is scheduled in bulk.
   *
   * @param sessions - List of sessions.
   * @param isRecommended - Optional boolean recommendation flag descibing the recommendation origin of the session, defaults to true when not set.
   * @param conversationId - Optional identifier of the assistant session initiated by the user.
   *  
   */
  public teaLogBulkSchedulingEvent(
    sessions: TypeSession[],
    isRecommended?: boolean,
    conversationId?: string): void {
      try{
        if(sessions){
          sessions.forEach(s=>{
            this.teaLogScheduleManagement([{
              scheduleManagementOperation: ScheduleManagementOperation[ScheduleManagementOperation.addSession],
              targetSessionTitle: s.sessionTitle,
              targetSessionType: s.sessionType,
              targetSessionTimeId: s.sessionTimeId,
              isRecommended: isRecommended ? isRecommended : true,
              conversationId: conversationId,
              targetTimeSlotStart: undefined,
              targetTimeSlotEnd: undefined
            }]);
          });
        }
      } catch(e){
        this.logToConsole("error", e);
      }
  }

  /**
   * Trigger a Tealium logging event of type scheduleConflict.
   * 
   * @remarks Use for agenda or schedule conflicts.
   *
   * @param decision - Decision over the conflict, action taken.
   * @param previouslyScheduledSessionTitle - Title of the session already enrolled.
   * @param newlyChosenSessionTitle - Title of the session added.
   * @param previouslyScheduledSessionTimeId - Session Time ID of the session already enrolled.
   * @param newlyChosenSessionTimeId - Session Time ID of the session added.
   * 
   */
  public teaLogScheduleConflictAction(
    decision: ScheduleConflictOperation,
    previouslyScheduledSessionTitle: string,
    newlyChosenSessionTitle: string,
    previouslyScheduledSessionTimeId?: string,
    newlyChosenSessionTimeId?: string): void {
      try{
        this.teaLogScheduleConflict([{
          scheduleConflictDecision: ScheduleConflictOperation[decision],
          previouslyScheduledSessionTitle,
          newlyChosenSessionTitle,
          previouslyScheduledSessionTimeId,
          newlyChosenSessionTimeId
        }]);
      } catch(e){
        this.logToConsole("error", e);
      }
  }

  /**
   * Trigger a Tealium logging event of type expressInterest.
   * 
   * @remarks Use for session and exhibitor favoriting or unfavoriting.
   *
   * @param operation - Decision over the action taken.
   * @param interest - Interest data representing a session or an exhibitor.
   * @param isRecommended - Set true if for operations involving sessions or exhibitor recommended to the user.
   * 
   */
  public teaLogExpressInterestAction(
    operation: InterestOperation,
    interest: TypeInterest,
    isRecommended?: boolean): void {
      try{
        this.teaLogExpressInterest([{
          interestOperation: InterestOperation[operation],
          interestId: interest.exhibitorID? interest.exhibitorID : interest.sessionId,
          interestType: InterestType[interest.type],
          interestAbbreviation: interest.abbreviation,
          interestTitle: interest.title,
          isRecommended,
          conversationId: undefined
        }]);
      } catch(e){
        this.logToConsole("error", e);
      }
  }

  /**
   * Trigger a Tealium logging event of type expressInterest.
   * 
   * @remarks Use for session and exhibitor favoriting or unfavoriting.
   *
   * @param operation - Decision over the action taken.
   * @param interest - Interest data representing a session or an exhibitor.
   * @param isRecommended - Set true if for operations involving sessions or exhibitor recommended to the user.
   * 
   */
  public teaLogBulkExpressInterestAction(
    operation: InterestOperation,
    interest: TypeInterest[],
    isRecommended?: boolean,
    conversationId?: string): void {
      try{
        
        this.teaLogExpressInterest(
          interest.map(i => {
            return {
              interestOperation: InterestOperation[operation],
              interestId: i.exhibitorID? i.exhibitorID : i.sessionId,
              interestType: InterestType[i.type],
              interestAbbreviation: i.abbreviation,
              interestTitle: i.title,
              isRecommended,
              conversationId
            };
          })
        );
      } catch(e){
        this.logToConsole("error", e);
      }
  }

  /**
   * Trigger a Tealium logging event of type surveyActivity.
   * 
   * @remarks Use for survey related operations, answering questions, saving-for-laters, submitting.
   *
   * @param surveyActivityStart - Date-time of the beginning of the operation.
   * @param operation - Action taken.
   * @param surveyedSessionTitle - Title of the Session surveyed.
   * @param surveyedSessionId - ID of the Session surveyed. 
   * 
   */
  public teaLogSurveyAction(
    surveyActivityStart: Date,
    operation: SurveyActivityOperation,
    surveyedSessionTitle: string,
    surveyedSessionId: string,
    ): void {
      try{
        this.teaLogSurveyActivity([{
          surveyActivityOperation: SurveyActivityOperation[operation],
          browsingMilliseconds: new Date().getTime() - surveyActivityStart.getTime(),
          surveyedSessionTitle,
          surveyedSessionId,
        }]);
      } catch(e){
        this.logToConsole("error", e);
      }
  }

  /**
   * Trigger a Tealium logging event of type sessionBrowsing.
   * 
   * @remarks Use for session cards or session details viewing.
   *
   * @param sessionBrowsingEntryTime - Date-time of the beginning of the viewing action.
   * @param targetSessionTitle - Title of the session reviewied.
   * @param targetSessionType - Type of the session reviewied.
   * @param targetSessionTimeId - Session Time ID of the session reviewied.
   * 
   */
  public teaLogSessionBrowsingAction(
    sessionBrowsingEntryTime: Date,
    targetSessionTitle: string,
    targetSessionType?: string,
    targetSessionTimeId?: string): void {
      try{
        this.teaLogSessionBrowsing([{
          browsingMilliseconds: new Date().getTime() - sessionBrowsingEntryTime.getTime(),
          targetSessionTitle,
          targetSessionType,
          targetSessionTimeId
        }]);
      } catch(e){
        this.logToConsole("error", e);
      }
  }

  /**
   * Trigger a Tealium logging event of type speakerBrowsing.
   * 
   * @remarks Use for speaker cards or speaker details viewing.
   *
   * @param speakerBrowsingEntryTime - Date-time of the beginning of the viewing action.
   * @param targetSpeakerName - Full name of the speaker reviewied.
   * @param targetSpeakerId - ID of the speaker reviewied.
   * 
   */
  public teaLogSpeakerBrowsingAction(
    speakerBrowsingEntryTime: Date,
    targetSpeakerName: string,
    targetSpeakerId?: string): void {
      try{
        this.teaLogSpreakerBrowsing([{
          browsingMilliseconds: new Date().getTime() - speakerBrowsingEntryTime.getTime(),
          targetSpeakerName,
          targetSpeakerId,
        }]);
      } catch(e){
        this.logToConsole("error", e);
      }
  }

  /**
   * Trigger a Tealium logging event of type scheduleBrowsing.
   * 
   * @remarks Use for agenda or schedule viewing.
   *
   * @param scheduleBrowsingEntryTime - Date-time of the beginning of the viewing action.
   * @param section - Section of the schedule reviewd, i.e. mySchedule(generic), auxiliary meetings, or executive 1:1.
   * 
   */
  public teaLogScheduleBrowsingAction(
    scheduleBrowsingEntryTime: Date,
    section: ScheduleSection): void {
      try{
        this.teaLogScheduleBrowsing([{
          browsingMilliseconds: new Date().getTime() - scheduleBrowsingEntryTime.getTime(),
          scheduleSection: ScheduleSection[section]
        }]);
      } catch(e){
        this.logToConsole("error", e);
      }
  }

  /**
   * Trigger a Tealium logging event of type carouselActivity.
   * 
   * @remarks Use for carousel viewing or shuffling.
   *
   * @param carouselActivityEntryTime - Date-time of the beginning of the action.
   * @param operation - Carousel operation performed.
   * 
   */
  public teaLogCarouselActivityAction(
    carouselActivityEntryTime: Date,
    operation: CarouselActivityOperation): void {
      try{
        this.teaLogCarouselActivity([{
          browsingMilliseconds: new Date().getTime() - carouselActivityEntryTime.getTime(),
          carouselActivityOperation: CarouselActivityOperation[operation]
        }]);
      } catch(e){
        this.logToConsole("error", e);
      }
  }

  /**
   * Trigger a Tealium logging event of type buttonActivity.
   * 
   * @remarks Use generic button click operations that DO NOT fall within the other logging categories.
   *
   * @param operation - Operation performed.
   * 
   */
  public teaLogButtonAction(operation: string): void {
    try{
      this.teaLogButtonActivity([{
        operation
      }])
    } catch(e){
      this.logToConsole("error", e);
    }
  }

  /**
   * Trigger a Tealium logging event of type promptSubmision.
   * 
   * @remarks Use for prompt submissions to generative ai assistants.
   *
   * @param prompt - String representing the user input.
   * @param conversationId - Optional identifier of the assistant session initiated by the user.
   * @param exchangeId - Optional exchange instance identifier of the assistant session, the exchange is defined by the pair user prompt and corresponding AI assistant response.
   * 
   */
  public teaLogPromptSubmissionAction(
    prompt: string,
    conversationId?: string,
    exchangeId?: string): void {
      try{
        this.teaLogPromptSubmision([{
          prompt,
          conversationId,
          exchangeId
        }]);
      } catch(e){
        this.logToConsole("error", e);
      }
  }

  /**
   * Trigger a Tealium logging event of type promptFeedback.
   * 
   * @remarks Use for submissions of user feedback to prompt responses from generative ai assistants.
   *
   * @param response - String representing the assistant output.
   * @param conversationId - Optional identifier of the assistant session initiated by the user.
   * @param positive - When set to true, express positive feedback of the user on the ai assistant response, negative when false.
   * @param comment - Optional user comment on the response provided by the ai assistant.
   * 
   */
  public teaLogPromptFeedbackAction(
    conversationId: string,
    response: string,
    positive: boolean,
    comment?: string): void {
      try{
        this.teaLogPromptFeedback([{
          response,
          conversationId,
          positive,
          comment
        }]);
      } catch(e){
        this.logToConsole("error", e);
      }
  }

  /**
   * Trigger a Tealium logging event of type recommendationReceipt.
   * 
   * @remarks Use for any new set of session and exhibitor recommendation issued by the AI assistant.
   *
   * @param recommendations - Heterogeneous list of recommendations, containing sessions and exhibitor.
   * @param conversationId - Optional identifier of the assistant session initiated by the user.
   * @param exchangeId - Optional exchange instance identifier of the assistant session, the exchange is defined by the pair user prompt and corresponding AI assistant response.
   *  
   */
  public teaLogRecommendationReceiptEvent(
    recommendations: TypeRecommendation[],
    conversationId?: string,
    exchangeId?: string): void {
      try{
        if(recommendations){
          this.teaLogRecommendationReceipt(
            recommendations.map(r=>{
            let recommendationId;
            if(r.sessionTimeId){
                recommendationId = r.sessionTimeId;
            } else if(r.exhibitorId){
                recommendationId = r.exhibitorId;
            } else {
                recommendationId = r.sessionId;
            }
            
            return {
                conversationId,
                exchangeId,
                recommendationId,
                recommendationType: r.source ? r.source : RecommendationType[RecommendationType.session],
                recommendationScore: r.recommendationScore,
                recommendationAbbreviation: r.abbreviation,
                recommendationTitle: r.sessionId ? r.title : r.name,
            }
            })
          );
        }
        
      } catch(e){
        this.logToConsole("error", e);
      }
  }
  
  /**
   * Trigger a Tealium logging event of type recommendationManagement.
   * 
   * @remarks Use for recommendation operations such as rejections, enrollments, favoriteing, rolling back to previous, expressing feedback.
   *
   * @param operation - Performed operation.
   * @param recommendations - Heterogeneous list of recommendations, containing sessions and exhibitor.
   */
  public teaLogRecommendationManagementAction(
    operation: RecommendationManagementOperation,
    recommendations: TypeRecommendation[]): void {
      try{
        if(recommendations){
          this.teaLogRecommendationManagement(
            recommendations.map(r=>{
              let recommendationId;
              if(r.sessionTimeId){
                  recommendationId = r.sessionTimeId;
              } else if(r.exhibitorId){
                  recommendationId = r.exhibitorId;
              } else {
                  recommendationId = r.sessionId;
              }
              return {
                recommendationManagementOperation: RecommendationManagementOperation[operation],
                targetRecommendationId: recommendationId,
                targetRecommendationType: r.source ? r.source : RecommendationType[RecommendationType.session],
                targetRecommendationAbbreviation: r.abbreviation,
                targetRecommendationTitle: r.sessionId ? r.title : r.name,
              }
            })
        )};
      } catch(e){
        this.logToConsole("error", e);
      }
  }
  
  /**
   * Trigger a Tealium logging event of type badgeScanActivity.
   * 
   * @remarks Use for badge scanning.
   *
   * @param badgeId - ID of the badge.
   * @param location - Location of the scan.
  */
  public teaLogBadgeScanAction(
    badgeId: string,
    location: string): void {
      try{
        this.teaLogBadgeScanActivity([{
          badgeId,
          location
        }]);
      } catch(e){
        this.logToConsole("error", e);
      }
  }
  
  /**
   * Trigger a Tealium logging event of type exceptionOccurrence.
   * 
   * @remarks Use for exception scenarios deviating from the regular execution of the program.
   *
   * @param errorCode - Status code representing the error.
   * @param errorMessage - Descriptive message of the error.
   * @param errorType - Classification of the error.
   * @param impact - Optional descritpion of component, functionality or user operation impacted.
   * @param origin - Optional origin of component, or module where the exception occurred.
  */
  public teaLogExceptionOccurrence(
    errorCode: string,
    errorMessage: string,
    errorType: string,
    impact?: string,
    origin?: string): void {
      try{
        this.teaLogExceptionCatching([{
          errorCode,
          errorMessage,
          errorType,
          impact,
          origin
        }]);
      } catch(e){
        this.logToConsole("error", e);
      }
  }

  /**
   * Trigger a Tealium logging event of type heartBeat.
   * 
   * @remarks Use for verifying the online status of a device or a SPA.
   *
   * @param message - Heart Beat message to send periodically.
   * @param origin - Optional origin of component, or module where the heart beat pulse originated.
  */
  public teaLogHeartBeatStatus(
    message: string,
    origin?: string): void {
      try{
        var self = this;
        // self.teaLogHeartBeat([{
        //   message,
        //   origin
        // }])
        const intervalId = setInterval(function() {
              self.teaLogHeartBeat([{
                message,
                origin
              }])
            }, 
            this.heartBeatDelay);
        this.activeIntervals.push({intervalId, heartBeat: {message, origin}});
      } catch(e){
        this.logToConsole("error", e);
      }
  }

  private clearIntervals(): void {
    this.activeIntervals.map(i => i.intervalId).forEach(id => {
      try {
        clearInterval(id);
      } catch (e) {
        this.logToConsole("error", e);
      }
    })
  }

  private recordEntry<T>(viewId, entry: any): T{
    entry.viewDate = new Date();
    if(this.viewHistory.hasOwnProperty(viewId)){
      const previousEntry: any = this.viewHistory[viewId];
      previousEntry.browsingMilliseconds = entry.viewDate.getTime() - previousEntry.viewDate.getTime();
      this.viewHistory[viewId] = entry;
      return previousEntry as T;
    } else {
      this.viewHistory[viewId] = entry;
      return this.viewHistory[viewId] as T;
    }
  }

  public clearHistory(viewId?: string): void{
    if(viewId){
      try {
          const entry = this.viewHistory[viewId];
          entry.browsingMilliseconds = new Date().getTime() - entry.viewDate.getTime();
          delete entry.viewDate;
          this.teaLogContentBrowsing(entry);
          delete this.viewHistory.entry
      }
      catch (e) {
          this.logToConsole("error", e);
      }
    } else {
      for(const [viewId] of Object.entries(this.viewHistory)){
        this.clearHistory(viewId);
      }
    }
  }

  // Hidden Service Methods
  /**
  * @deprecated The method should not be used, use teaLogContentBrowsing instead
  */
  private teaLogRouterNavigation(metadata: IRouterNavigationMetadata[]): void {
    this.teaTrack(EventType.routerNavigation, metadata.map((m: IRouterNavigationMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogContentBrowsing(metadata: IContentBrowsingMetadata[]): void {
    this.teaTrack(EventType.contentBrowsing, metadata.map((m: IContentBrowsingMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogHyperlinkNavigation(metadata: IHyperlinkNavigationMetadata[]): void {
    this.teaTrack(EventType.hyperlinkNavigation, metadata.map((m: IHyperlinkNavigationMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogContentDownload(metadata: IContentDownloadMetadata[]): void {
    this.teaTrack(EventType.contentDownload, metadata.map((m: IContentDownloadMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogContentSearch(metadata: IContentSearchMetadata[]): void {
    this.teaTrack(EventType.contentSearch, metadata.map((m: IContentSearchMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogScheduleManagement(metadata: IScheduleManagementMetadata[]): void {
    this.teaTrack(EventType.scheduleManagement, metadata.map((m: IScheduleManagementMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogExpressInterest(metadata: IInterestMetadata[]): void {
    this.teaTrack(EventType.expressInterest, metadata.map((m: IInterestMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogScheduleConflict(metadata: IScheduleConflictMetadata[]): void {
    this.teaTrack(EventType.scheduleConflict, metadata.map((m: IScheduleConflictMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogSurveyActivity(metadata: ISurveyActivityMetadata[]): void {
    this.teaTrack(EventType.surveyActivity, metadata.map((m: ISurveyActivityMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogSessionBrowsing(metadata: ISessionBrowsingMetadata[]): void {
    this.teaTrack(EventType.sessionBrowsing, metadata.map((m: ISessionBrowsingMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogSpreakerBrowsing(metadata: ISpeakerBrowsingMetadata[]): void {
    this.teaTrack(EventType.speakerBrowsing, metadata.map((m: ISpeakerBrowsingMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogScheduleBrowsing(metadata: IMyScheduleBrowsingMetadata[]): void {
    this.teaTrack(EventType.scheduleBrowsing, metadata.map((m: IMyScheduleBrowsingMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogCarouselActivity(metadata: ICarouselActivityMetadata[]): void {
    this.teaTrack(EventType.carouselAction, metadata.map((m: ICarouselActivityMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogButtonActivity(metadata: IButtonActivityMetadata[]): void {
    this.teaTrack(EventType.buttonAction, metadata.map((m: IButtonActivityMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogPromptSubmision(metadata: IPromptSubmissionMetadata[]): void {
    this.teaTrack(EventType.promptSubmission, metadata.map((m: IPromptSubmissionMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogPromptFeedback(metadata: IPromptFeedbackMetadata[]): void {
    this.teaTrack(EventType.promptFeedback, metadata.map((m: IPromptFeedbackMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogRecommendationReceipt(metadata: IRecommendationReceiptMetadata[]): void {
    this.teaTrack(EventType.recommendationReceipt, metadata.map((m: IRecommendationReceiptMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogRecommendationManagement(metadata: IRecommendationManagementMetadata[]): void {
    this.teaTrack(EventType.recommendationManagement, metadata.map((m: IRecommendationManagementMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogBadgeScanActivity(metadata: IBadgeScanMetadata[]): void {
    this.teaTrack(EventType.badgeScanActivity, metadata.map((m: IBadgeScanMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogExceptionCatching(metadata: IExceptionOccurrence[]): void {
    this.teaTrack(EventType.exceptionOccurrence, metadata.map((m: IExceptionOccurrence) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  private teaLogHeartBeat(metadata: IHeartBeatMetadata[]): void {
    this.teaTrack(EventType.heartBeat, metadata.map((m: IHeartBeatMetadata) => {return { ...m, ...this.user, ...this.navigator }}));
  }

  protected preAuthorizeEventLogging(): void {
    if (!this.user)
      throw new Error(`${this.config.logging.message.tracking}: ${this.config.logging.message.unresolvedUser}.`);
    if (!this.user.eventId)
      throw new Error(`${this.config.logging.message.tracking}: ${this.config.logging.message.unresolvedEvent}.`);
    if (this.config.allowTestUserTracking && this.user.isTestRecord)
      throw new Error(`${this.config.logging.message.tracking}: ${this.config.logging.message.unqualifiedUser}.`);
  }

  private teaTrack(eventType: EventType, data: ITeaLogData[]): void {
    try{
      this.preAuthorizeEventLogging();
    } catch (error){
      this.logToConsole("error", error);
      return;
    }
    this.logToConsole("info", { event: EventType[eventType], data });
    try{
      if(this.config.settings.nativeTracking){
        this.trackWithCiscoEvents(eventType, data);
      }
      if(this.config.settings.cloudTracking){
        this.getPublisherToken().then(auth => this.publishToPubSubQueue(auth, eventType, data));
      }
      cdc = legacyInitCDC();
      data.forEach(d => { 
        trackEvent.event('link', { event: EventType[eventType], ...d }); });
    } catch(error){
      this.logToConsole("error", error);
    }
  }

  private trackWithCiscoEvents(eventType: EventType, data: ITeaLogData[]): void{
    try{
      const body: string = JSON.stringify(data.map((d: ITeaLogData) => { return { tealiumEvent: EventType[eventType], ...d, ...this.defaultUser }}));
      fetch(`https://${this.config.data.nativeTrackingHost}/api/e/${this.defaultUser.eventId}/tealium?key=${this.config.data.key}`, {
          method: 'POST',
          body,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
    } catch(e){
      this.logToConsole("error", e);
    }
  }

  private publishToPubSubQueue(auth: JWTToken, eventType: EventType, data: ITeaLogData[]): void {
    try{
      const body = JSON.stringify({
          messages:  data.map((d) => {
              return { 
                  attributes: {
                      eventId: this.defaultUser.eventId,
                      eventType: this.eventType,
                  },
                  data: btoa(
                    JSON.stringify(
                      Object.assign(
                        Object.assign({ 
                          tealiumEvent: EventType[eventType], 
                          dateTime: { "$date": new Date().toISOString() },
                        }, d), 
                        this.defaultUser)))
              }
          })
      });
      fetch(`https://pubsub.googleapis.com/v1/projects/${this.config.data.pubSubProjectId}/topics/${this.config.data.pubSubTopic}:publish`, {
          method: 'POST',
          body,
          headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${auth.access_token}`,
              'Content-Type': 'application/json'
          }
      });
    } catch(e){
      this.logToConsole("error", e);
    }
  }

  private resolveNavigator(): { platform: string, vendor: string, brand: string, kiosk: boolean, mobile: boolean, webView: boolean, userAgent: string} {
    const nav: any = {};
    try {
      nav.platform = (window.navigator as any).userAgentData.platform;
    } catch (e){
      this.logToConsole("error", e);
    }
    try {
      nav.mobile = (window.navigator as any).userAgentData.mobile;
    } catch (e){
      this.logToConsole("error", e);
    }
    try {
      nav.vendor = (window.navigator as any).vendor;
    } catch (e){
      this.logToConsole("error", e);
    }
    try {
      nav.brand = (window.navigator as any).userAgentData.brands[0]?.brand;
    } catch (e){
      this.logToConsole("error", e);
    }
    try {
      nav.kiosk = this.isCiscoBoard();
    } catch (e){
      this.logToConsole("error", e);
    }
    try {
      nav.userAgent = window.navigator.userAgent;
    } catch (e){
      this.logToConsole("error", e);
    }
    try {
      nav.webView = this.isWebView();
    } catch (e){
      this.logToConsole("error", e);      
    }
    return nav;
  }

  private isWebView(): boolean {
    const userAgent = navigator.userAgent;
    return (/wv/.test(userAgent) || /Android.*WebView/.test(userAgent)) || (/iPhone|iPod|iPad.*AppleWebKit(?!.*Safari)/.test(userAgent));
  }

  private isCiscoBoard(): boolean {
    const userAgent = navigator.userAgent;
    return (/Cisco Board/.test(userAgent));
  }

  private resolveEventType(eventId: string): string {
    if(eventId.startsWith("ds1c")){
      return "Cisco Live"
    } else if(eventId.startsWith("ds1p")){
      return "Partner Summit"
    }  else if(eventId.startsWith("ds1g")){
      return "GSX"
    } 
    return undefined;
  }
  
  private getPublisherToken(): Promise<JWTToken> {
    if (!this.publisherToken || this.publisherToken.isExpired()) {
        return this.fetchPublisherToken().then(jwt => {this.publisherToken = jwt; return jwt;});
    } 
    else if (this.publisherToken.refreshRequired(this.publisherAuthAOTDelay)) {
        this.fetchPublisherToken().then(jwt => this.publisherToken = jwt);
    }
    return new Promise((recoverValidAuthentication) => recoverValidAuthentication(this.publisherToken));
  }

  private fetchPublisherToken(): Promise<JWTToken>{
    const body = "{}";
    return fetch(`https://${this.config.data.nativeTrackingHost}/api/e/${this.defaultUser.eventId}/tealium/auth?key=${this.config.data.key}`, {
      method: 'POST',
      body,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(body => new JWTToken(body['data']))
  }

  private logToConsole(level: string, data: any): void{
    const logLevel = ["none", "error", "warn", "info", "debug", "trace"];
    if(logLevel.indexOf(level) <= logLevel.indexOf(this.config.logging.level)){
      switch (level) {
        case 'trace': 
          console.trace(data);
          break;
        case 'debug':
          console.debug(data);
          break;
        case 'info': 
          console.info(data);
          break;
        case 'warn':
          console.warn(data);
          break;
        case 'error':
          console.error(data);
          break;
        case 'none':
        default: 
          break;
      }
    }    
  }

}
import { CDC, EventType, ICDC, IOktaJWT, ScheduleConflictOperation, ScheduleManagementOperation, SurveyActivityOperation } from "./tealog.model";

export function initCDC(): ICDC {
    return new CDC();
}
export function legacyInitCDC(): any {
    var cdc: any;
    var trackEvent: any;
    if (!(typeof trackEvent != "undefined" && typeof trackEvent.event != "undefined")) {     
        //trackEvent API isn't available. Hence create temporary API to queue all the trackEvent request to a global eventqueue which will be processed once the CTM's trackEvent API gets loaded.
        if (typeof cdc == "undefined") cdc = {};
        if (typeof cdc.ut == "undefined") cdc.ut = {};
        if (typeof cdc.ut.eventqueue == "undefined") cdc.ut.eventqueue = {};
        if (typeof cdc.ut.eventqueue.q == "undefined") cdc.ut.eventqueue.q = [];
        if (typeof cdc.ut.trackEvent == "undefined") {
             cdc.ut.trackEvent = {
                  event: function(a, b) {
                       try {
                            //console.log("Pushing trackEvent call on to Q");
                            var c = "";
                            for (var d in b) c += "&" + d + "=" + b[d];
                            cdc.ut.eventqueue.q.push({
                                 a: a,
                                 b: b
                            });
                       } catch (e) {
                            console.log("Unable to create cdc.ut.trackEvent.event function:", e);
                       }
                  }
             };
        }   
   }
   return cdc;
}

export function validateEventType(eventType: string) {
    if(!Object.values(EventType).includes(eventType)){
        throw new Error(`Invalid Event Type: the action '${eventType}' you are trying to record is not supported. Allowed actions include ${Object.values(EventType)}`);
    }
}

export function validateScheduleManagementOperation(scheduleManagementOperation: string) {
    if(!Object.values(ScheduleManagementOperation).includes(scheduleManagementOperation)){
        throw new Error(`Invalid Schedule Management Operation: the action '${scheduleManagementOperation}' is not supported. Allowed actions include ${Object.values(ScheduleManagementOperation)}`);
    }
}

export function validateScheduleConflictOperation(scheduleConflictOperation: string) {
    if(!Object.values(ScheduleConflictOperation).includes(scheduleConflictOperation)){
        throw new Error(`Invalid Schedule Conflict Operation: the action '${scheduleConflictOperation}' you are trying to record is not supported. Allowed actions include ${Object.values(ScheduleConflictOperation)}`);
    }
}


export function validateSurveyActivityOperation(surveyActivityOperation: string) {
    if(!Object.values(SurveyActivityOperation).includes(surveyActivityOperation)){
        throw new Error(`Invalid Survey Operation: the action '${surveyActivityOperation}' you are trying to record is not supported. Allowed actions include ${Object.values(SurveyActivityOperation)}`);
    }
}

export function resolveAuth(encodedToken: string): IOktaJWT{
    if (!encodedToken){
        return;
    }
    const jwt = encodedToken.split('.');
    return JSON.parse(atob(jwt[1])) as IOktaJWT;
}


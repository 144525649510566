const CiscoAiAuth = (() => {

  const fetchAccessTokenForAttendee = async (eventId, badgeId) => {

    const apiUrl = `${process.env.REACT_APP_CISCO_EVENTS_API_URL}/api/e/${eventId}/user/ds1/badge/${badgeId}`;

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    return data.data.access_token;
  };

  return {
    fetchAccessTokenForAttendee
  };
})();

export default CiscoAiAuth;

import React, { useEffect, useState, useRef } from "react";
import MapComponent from "../common-pointr-map-container/GenericPointrMapContainer"
import LeftContainer from "../left-container/left-container";
import HeaderContainer from "../header-container/header-container";
import RightContainer from "../right-container/right-container";
import SearchPopup from '../main-container/SearchPopup'; // Import SearchPopup component
import FooterContainer from '../footer-container/footer-container';
import { useMap } from '../../../context/MapContext'; // Import the useMap hook
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import { handleReset } from '../main-container/handleResetView'; // Import the functions
import { calculateMidPoint, manageLayers } from '../main-container/markCurrentPosition'; // Import the functions
import { fetchGsxData } from '../left-container/fetchZonesHubwalksInfo';
import CustomPopup from '../main-container/DirectionPopup';
import ciscoSpacesPopupIcon from '../../../../assets/images/powered-by-spaces-cl.png';
import Screensaver from "../countdown-timer-container/screensaver";
import ciscoSpacesPopupImage from '../../../../assets/images/cisco-space-popup-cl.png';
import {handleWhereAmI} from '../footer-container/handle-where-am-i';  



const CiscoLiveMainContainer = () => {
  const kioskData = {
    "a9ce3d34-3c38-4a41-97f9-e0b89dd30633": {
      "lat": -37.82479674138271,
      "long": 144.95167859548957,
      "rotation": 0.7,
      "bearing": -25,
      "building": 3,
      "level":0
    },
    "519947ec-b3d6-469e-9f92-8af9ce17485a": {
      "lat": -37.82511489123363,
      "long": 144.95308527959907,
      "rotation": -1,
      "bearing": 55,
      "building": 3,
      "level":0
    },
    "1ffd22e6-63f4-4bff-9793-4367341ab6a8": {
      "lat": -37.825884861567644,
      "long": 144.95266828784526,
      "rotation": 0.65,
      "bearing": -55,
      "building": 3,
      "level": 0
    },
    "cf80d1e5-9256-40aa-bea2-3107c01a4588": {
      "lat": -37.8241857302036,
      "long": 144.95566915139398,
      "rotation": 2.5,
      "bearing": -165,
      "building": 2,
      "level": 0
    },
    "6dca7b0c-3635-4cd6-9a83-7e3d5973ee30": {
      "lat": -37.82592052384373,
      "long": 144.95176122649218,
      "rotation": -2.5,
      "bearing": 125,
      "building": 4,
      "level": 0
    },
    "9c331412-8e29-4015-8d4d-dcff58b93c90": {
      "lat": -37.82462013771634,
      "long": 144.9513932591833,
      "rotation": 1.7,
      "bearing": -96,
      "building": 3,
      "level": 1
    },
    "a328b0b4-5644-4f3d-b673-942d2a12e349": {
      "lat": -37.82460587862206,
      "long": 144.95240123053998,
      "rotation": 0.18,
      "bearing": -8,
      "building": 3,
      "level": 2
    },
    "62d5de0b-cf0f-4c9b-a09e-fc4fe9b8483e": {
      "lat": -37.824828940950084,
      "long": 144.95146675654854,
      "rotation": 1.7,
      "bearing": -96,
      "building": 3,
      "level": 2
    },
    "bed269a8-0d52-4a93-bbd6-19a69355d124": {
      "lat": -37.82587840378705,
      "long": 144.95303388152325,
      "rotation": -2.5,
      "bearing": 141.86,
      "building": 2,
      "level": 0
    },
    "6de1195d-a3d3-40ab-858c-a442548938df": {
      "lat": -37.826400272567696,
      "long": 144.95217303242583,
      "rotation": 2.2,
      "bearing": -130,
      "building": 2,
      "level": 0
    },
    "a6d3bb02-7d83-42ab-86c3-e8ce985d80e0": {
      "lat": -37.826626827895915,
      "long": 144.95308734960042,
      "rotation": 3.8,
      "bearing": 135,
      "building": 2,
      "level": 0
    },
    "d6673fdc-9ab2-400c-9d9d-d9c621528ef4": {
      "lat": -37.82603410522287,
      "long": 144.9534654020547,
      "rotation": 5.3,
      "bearing": 52,
      "building": 2,
      "level": 0
    }
  };

  const { map } = useMap();

  const [isActive, setIsActive] = useState(true);
  const [timer, setTimer] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [isZonesInfoPopulated, setIsZonesInfoPopulated] = useState(false);

  const [zonesInfo, setZonesInfo] = useState([]);
  const [hubwalksInfo, setHubwalksInfo] = useState([]);
  const [facilitiesData, setFacilitiesData] = useState([]);
  const hasPassedDestFidRef = useRef(false);

  const timerRef = useRef(null);
  const [popupState, setPopupState] = useState({ poi: null, coordinates: null }); // Add state for the popup
  const [ciscoSpacesPopup, setCiscoSpacesPopup] = useState(false);
  const [isPointrSDKLoaded, setIsPointrSDKLoaded] = useState(false); // State to track Pointr SDK load status
  const [eventStartDateData, setEventStartDateData] = useState({
    year: 2024,
    month: 10,
    day: 11
  });


  const [eventEndDateData, setEventEndDateData] = useState({
    year: 2024,
    month: 10,
    day: 14
  });

  const tenantId = "263251";
  const locationId = "b5c82365-94d8-4fb0-8bec-231078862470";
  const siteExternalIdentifier = "b5c82365-94d8-4fb0-8bec-231078862470";
  const timeZoneCity = "Melbourne";
  const timeZone = "Australia/Melbourne";
  const qrCodeExpiresAt = 1735689598
  const eventLogoName = "cisco-live.svg"
  const eventName = "CLAPJC"
  const eventTitle = "Melbourne"
  const eventSubTitle = ""

  const buildingInfo = [
    {
        "buildingTitle": "MEC (Melbourne Exhibition Centre)",
        "buildingExternalIdentifier": "a8986cae-b840-4121-98da-9574dae3b50e",
        "buildingInternalIdentifier": 2,
        "siteInternalIdentifier": 2,
        "levels": [
            {
                "levelIndex": 0,
                "levelLongTitle": "Ground Floor",
                "levelShortTitle": "GF"
            },
            {
                "levelIndex": 1,
                "levelLongTitle": "First Floor",
                "levelShortTitle": "1F"
            },
            {
                "levelIndex": 4,
                "levelLongTitle": "Fourth Floor",
                "levelShortTitle": "4F"
            }
        ]
    },
    {
        "buildingTitle": "MCC (Melbourne Convention Centre)",
        "buildingExternalIdentifier": "f1b1bc4f-f6d5-4a25-95d2-f3e635ffbdae",
        "buildingInternalIdentifier": 3,
        "siteInternalIdentifier": 2,
        "levels": [
            {
                "levelIndex": 0,
                "levelLongTitle": "Ground Floor",
                "levelShortTitle": "GF"
            },
            {
                "levelIndex": 1,
                "levelLongTitle": "First Floor",
                "levelShortTitle": "1F"
            },
            {
                "levelIndex": 2,
                "levelLongTitle": "Second Floor",
                "levelShortTitle": "2F"
            }
        ]
    },
    {
        "buildingTitle": "MEC Expansion",
        "buildingExternalIdentifier": "17e0bf2a-af81-4426-bdea-41fa9481131b",
        "buildingInternalIdentifier": 4,
        "siteInternalIdentifier": 2,
        "levels": [
            {
                "levelIndex": 0,
                "levelLongTitle": "Ground Floor",
                "levelShortTitle": "GF"
            },
            {
                "levelIndex": 1,
                "levelLongTitle": "First Floor",
                "levelShortTitle": "1F"
            }
        ]
    }
];
  // Or, if the keys are static, you can use an object:
  const buildingExID = {
    2: "a8986cae-b840-4121-98da-9574dae3b50e",
    3: "f1b1bc4f-f6d5-4a25-95d2-f3e635ffbdae",
    4: "17e0bf2a-af81-4426-bdea-41fa9481131b"
  };
  const levelExId ={
    "a8986cae-b840-4121-98da-9574dae3b50e":{
      0:"807c50b8-cdc9-4eab-bbde-fbdef811f379",
      1:"2979ac1c-3231-40d7-acb4-ee3cbb61a04a",
      4:"cbbe3aea-e58b-42c7-a23d-8ec42601f6ec"
    },
    "f1b1bc4f-f6d5-4a25-95d2-f3e635ffbdae":{
      '-1':"ea798c8a-94b3-46e7-869d-761197e2d0d5",
      0:"9f6c7d83-076a-4f59-aaf8-eb9bb27692c2",
      1:"5b0d64dc-6ab8-4a65-9f5f-88fb110feebe",
      2:"138e716a-4fec-4fed-92ae-d3b6e9a43f6e"
    },
    "17e0bf2a-af81-4426-bdea-41fa9481131b":{
      0:"49da0f42-27c5-4806-b4b9-e8507724a614",
      1:"62606aef-9ab5-4f0b-8eb6-ad0b8edcf1bb"
    }

  }

  const minMaxBounds = [
    [144.86072068220216,
      -37.89877005623881],
    [
      145.00783909842391,
      -37.80548990901803
    ]
  ];

  const getLocationId = (poi) => {
    const bid = poi?.properties?.bid;
    const lvl = poi?.properties?.lvl;
    const buildingId = buildingExID[bid];
  
    // Check if both buildingId and lvl exist in levelExId
    if (buildingId && lvl !== undefined && levelExId[buildingId]?.[lvl] !== undefined) {
      return levelExId[buildingId][lvl];
    }
  
    // Fallback to buildingId if lvl is not present or no match found
    return buildingId || locationId;
  };
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const kioskFid = query.get('kioskFid');
  const destFid = query.get('destFid');
  const latFromQuery = parseFloat(query.get('lat'));
  const longFromQuery = parseFloat(query.get('long'));
  const rotationFromQuery = parseFloat(query.get('rotation'));
  const zoomFromQuery = parseFloat(query.get('zoom'));
  const pitchFromQuery = parseFloat(query.get('pitch'));
  const bearingFromQuery = parseFloat(query.get('bearing'));
  const levelFromQuery = parseFloat(query.get('level'));
  const buildingFromQuery = parseFloat(query.get('building'));
  const centerLong = 144.95351645994242;
  const centerLat = -37.825165754545644;

  const [lat, setLat] = useState(kioskData[kioskFid]?.lat || (latFromQuery ? latFromQuery :-37.82625492047534));
  const [long, setLong] = useState(kioskData[kioskFid]?.long || (longFromQuery ? longFromQuery : 144.95365890970544));
  const [rotation, setRotation] = useState(kioskData[kioskFid]?.rotation || (rotationFromQuery ? rotationFromQuery:0));
  const [zoom, setZoom] = useState(kioskData[kioskFid]?.zoom || (zoomFromQuery ? zoomFromQuery : 17));
  const [pitch, setPitch] = useState(kioskData[kioskFid]?.pitch || (pitchFromQuery ? pitchFromQuery: 65));
  const [bearing, setBearing] = useState(kioskData[kioskFid]?.bearing || (bearingFromQuery ? bearingFromQuery : -70));
  const [level, setLevel] = useState(kioskData[kioskFid]?.level || (levelFromQuery ? levelFromQuery: 0));
  const [building, setBuilding] = useState(kioskData[kioskFid]?.building || (buildingFromQuery ? buildingFromQuery : 2));

  const [whereAmILevel, setWhereAmILevel] = useState(level);
  const [whereAmIBuilding, setWhereAmIBuilding] = useState(building);
  const [selectedFloor, setSelectedFloor] = useState(level);

  useEffect(() => {
    if (kioskFid && kioskData[kioskFid]) {
      const data = kioskData[kioskFid];
      setLat(data.lat || -37.82625492047534);
      setLong(data.long || 144.95365890970544);
      setRotation(data.rotation || -1);
      setZoom(data.zoom || 18);
      setPitch(data.pitch || 65);
      setBearing(data.bearing || -70);
      setLevel(data.level || 0);
      setWhereAmILevel(level);
      setBuilding(data.building || 2);
      setWhereAmIBuilding(building);
    }
  }, [kioskFid]);


  useEffect(() => {
    if (destFid && !hasPassedDestFidRef.current) {
      // Call any function or set up as needed for the first time `destFid` is passed
      hasPassedDestFidRef.current = true;
    }
  }, [destFid]);

  
  const showPopup = (poi, coordinates, cleanPopup = true,isMapClick=false) => {
    if (!poi) {
      if(popupState.poi){
        handleResetView(cleanPopup,isMapClick);
      }
      setPopupState({ poi: null, coordinates: null });
     
    } else {
      setPopupState({ poi, coordinates: (poi?.geometry?.type === 'Point') ? poi.geometry.coordinates : calculateMidPoint(poi.geometry.coordinates[0]) });
    }
  };
  const showCiscoSpacesPopup = () => {
    setCiscoSpacesPopup(true);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.PointrWebSDK) {
        setIsPointrSDKLoaded(true);
        clearInterval(interval);
      }
    }, 100); // Check every 100ms

    return () => clearInterval(interval);
  }, []);
  // Handler to hide the popup
  const hidePopup = () => {
    setPopupState({ poi: null, coordinates: null });
    handleResetView();
  };

  useEffect(() => {
    // Fetch data from the API only once when the component mounts
    const getZones = async () => {
      try {

        const data = await fetchGsxData(tenantId);
        console.log("gsx" + data);
        if(data.zones){
          setIsZonesInfoPopulated(true);
        }
        setZonesInfo(data.zones);
        setHubwalksInfo(data.hubWalks);
        setFacilitiesData(data.facilities);

      } catch (err) {

        console.log(err);

      }
    };

    getZones();
  }, []);

  // Simulate map loading
  useEffect(() => {
    // Simulate a delay for the map loading
    const mapLoadTimeout = setTimeout(() => {
      setIsMapLoaded(true);
      document.title = '2024 Cisco Live';
    }, 5000); // Adjust the delay as needed

    return () => clearTimeout(mapLoadTimeout);
  }, []);

  useEffect(() => {
    let intervalId;
  
    const fetchPoiUntilFound = async () => {
      if (map && destFid) {
        const poi = await map.getPoiManager().get(destFid);
        if (poi) {
          // Uncomment this line when ready to show the popup
          const uiController = map.getUiController();
          const mapViewController = uiController.getMapViewController();
          mapViewController.setLevel(poi.properties.lvl);
          mapViewController.highlightPoi(poi);
           showPopup(poi, poi?.geometry?.type === 'Point' ? poi.geometry.coordinates : calculateMidPoint(poi.geometry.coordinates[0]),false,false);
           clearInterval(intervalId); // Stop checking once the poi is found
        }
        
      }
    };
  
    // Set up polling to run fetchPoiUntilFound every 500ms (adjust as needed)
    intervalId = setInterval(fetchPoiUntilFound, 5000);
  
    return () => clearInterval(intervalId); // Clear interval on component unmount or when dependencies change
  }, [map, destFid]);


  useEffect(() => {
    const eventTypes = ["click", "mousemove", "keypress", "wheel", "gesturestart", "gesturechange", "gestureend", "touchmove", "touchstart", "touchend"];

    eventTypes.forEach(eventType => {
      window.addEventListener(eventType, handleActivity);
    });

    return () => {
      eventTypes.forEach(eventType => {
        window.removeEventListener(eventType, handleActivity);
      });
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => { }, [isActive]);


  const handleActivity = (event) => {
    setIsActive(true);
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setIsActive(false);
      console.log("No activity detected for 2 minutes." + event.type);
    }, 180000); // 2 minutes
  };


  const handleSearch = async (searchInput) => {
    hidePopup();
    const uiController = map.getUiController();
    const mapViewController = uiController.getMapViewController();
    const mapView = mapViewController.getView();
    mapView.setLayoutProperty("rmaps-symbol_selected_ptr", 'icon-image', 'green_marker');

    console.log(searchInput);
    let valueAfterTilde;
    let poiFid = searchInput?.fId;
    if (searchInput?.type === 'session') {
      poiFid = searchInput?.poiFId;

    }
    if (poiFid?.includes('~')) {
      valueAfterTilde = poiFid.split('~')[1];
    } else {
      valueAfterTilde = poiFid;
    }
    const poi = await map.getPoiManager().get(valueAfterTilde);
    if (poi) {
      map.unhighlight();
      mapViewController.setLevel(poi.properties.lvl);
      mapViewController.highlightPoi(poi);
      map.hidePoiDetails(poi);

      if (poi?._geometry?.coordinates[0]) {
        const latLong = (poi.geometry.type === 'Point') ? {
          lat: poi.geometry.coordinates[1],
          lng: poi.geometry.coordinates[0]
        } : calculateMidPoint(poi?._geometry?.coordinates[0]);

        //updateLayerFilterForSingleFeature(mapView, searchInput);
        mapView.map.flyTo({
          center: [latLong.lng, latLong.lat],
          zoom: 20, pitch: pitch, bearing: bearing, speed: 0.4
        });
        if (searchInput.type === 'session') {
          poi._properties.isSession = true;
          poi._properties.sessionsInfo = [searchInput];
          poi._properties.sessionStart= searchInput?.utcStartTime;

        }
        showPopup(poi, latLong);
        manageLayers(map?.getUiController().getMapViewController().getView(),poi?._properties?.bid,poi?._properties?.lvl)
      }
    }
  };

  useEffect(() => {
    if (searchInput) {
      handleSearch(searchInput);
    }
  }, [searchInput]);

  const handleResetView = (cleanPopup = false,isMapClick=false) => {
    handleReset(long ? long : 144.95371853, lat ? lat : -37.8253828, rotation ? rotation : -4.5, zoom ? zoom : 22, true, cleanPopup,isMapClick, pitch, bearing, whereAmIBuilding, whereAmILevel);
  };


  const handleResetViewButton = (cleanPopup = false) => {
    setPopupState({ poi: null, coordinates: null });
    handleResetView();
  };

  const handleResetViewButtonInitialZoom = (cleanPopup = false) => {
    setPopupState({ poi: null, coordinates: null });
    handleResetView();

    const uiController = map.getUiController();
    const mapViewController = uiController.getMapViewController();
    const mapView = mapViewController.getView();
    mapView.map.flyTo({
      center: [long, lat],
      zoom: 19.5,
      bearing: bearing,
      pitch: pitch,
      speed:0.4
    });
  };

  return (
    <div className="main-container">

      {isPointrSDKLoaded && <MapComponent showPopup={showPopup} tenantId={tenantId}
        locationId={locationId} siteExternalIdentifier={siteExternalIdentifier} lat={lat} long={long} zoom={zoom} pitch={pitch} bearing={bearing} rotation={rotation} eventName = {eventName} whereAmILevel={parseInt(whereAmILevel)} whereAmIBuilding={parseInt(whereAmIBuilding)} defaultBuilding={parseInt(building)} defaultLevel={parseInt(level)} setBuilding={setBuilding} setLevel={setLevel} timeZone={timeZone} eventStartDateData={eventStartDateData} centerLatLong={[centerLong,centerLat]}/>}
      {isMapLoaded && (
        
        <>
          {handleWhereAmI(map,long,lat,rotation,pitch,bearing,whereAmILevel, whereAmIBuilding)}
          <HeaderContainer
            showCiscoSpacesPopup={showCiscoSpacesPopup} timeZoneCity={timeZoneCity} 
            eventStartDateData = {eventStartDateData} eventEndDateData={eventEndDateData}  
            handleResetView={handleResetViewButton}  eventLogoName={eventLogoName} 
            eventName={eventName} defaultLevel={parseInt(level)} 
            defaultBuilding = {parseInt(building)} setBuilding={setBuilding} 
            setLevel={setLevel} pitch={pitch} 
            bearing={bearing} lat={lat} long={long} 
            rotation={rotation}
            eventTitle={eventTitle}
            eventSubTitle={eventSubTitle}
            eventTimeZone={timeZone}/>
          {zonesInfo &&
            <LeftContainer
              zoneData={zonesInfo} showPopup={showPopup} hidePopup={hidePopup} tenantId={tenantId}
              locationId={locationId} qrCodeExpiresAt={qrCodeExpiresAt}
              whereAmIBuilding={whereAmIBuilding}
              whereAmILevel={whereAmILevel}
              defaultLevel={parseInt(level)} 
              defaultBuilding = {parseInt(building)}
              handleResetView={handleResetViewButton}
              setBuilding={setBuilding}
              setLevel={setLevel} 
              lat={lat}
              long={long}
              rotation={rotation}
              showLevelSelector={true}
              isZonesInfoPopulated={isZonesInfoPopulated}
              isDestFidPopulated={destFid?true:false}
              selectedFloor={selectedFloor}
              setSelectedFloor={setSelectedFloor}
              buildingInfo={buildingInfo}
              centerLatLong={[centerLong,centerLat]}
               />}
          {isPointrSDKLoaded && <RightContainer
            hubwalksData={hubwalksInfo} facilitiesData={facilitiesData} pitch={pitch} bearing={bearing} centerLong={centerLong} centerLat={centerLat}/>}
          {(!isActive &&
            /**<CountdownTimer
              initialMinutes={0}
              initialSeconds={10}
              resetTimer={isActive} // Pass isActive as a prop to reset the timer
              showPopup={showPopup}
            />*/
            <Screensaver inactivityTimeout={1000} flipInterval={3000} showPopup={showPopup} ciscoSpacesPopupImage={ciscoSpacesPopupImage} lat={lat} long={long} zoom={zoom} rotation={rotation} pitch={pitch} bearing={bearing} whereAmILevel={whereAmILevel} centerLong={centerLong} centerLat={centerLat}/>

          )}
          {showSearch && (
            <SearchPopup
              setSearchInput={setSearchInput}
              setShowSearch={setShowSearch}
              map={map}
              tenantId={tenantId}
              placeholder={"Search for points of interest and other facilities"}
            />
          )}
          {popupState.poi && (
            <CustomPopup
              poi={popupState.poi}
              coordinates={popupState.coordinates}
              tenantId={tenantId}
              locationId={getLocationId(popupState.poi)}
              onCancel={hidePopup}
              source={"poiClick"}
              lat={lat}
              long={long}
              building={building}
              level={level}
              rotation={rotation}
              bearing={bearing}
              pitch={pitch}
              whereAmIBuilding={whereAmIBuilding}
              whereAmIlevel={whereAmILevel}
              qrCodeExpiresAt={qrCodeExpiresAt}
              destFid={hasPassedDestFidRef.current ? undefined : destFid} 
              setBuilding={setBuilding}
              setLevel={setLevel}
              eventStartDate={eventStartDateData}
              eventTimeZone={timeZone}
              setSelectedFloor={setSelectedFloor}
            />
          )}
          {ciscoSpacesPopup && (
            <div className="cisco-spaces-popup-overlay" >
              <div className="cisco-spaces-popup-overlay">
                <div className="cisco-spaces-popup-overlay-popup">
                  <img src={ciscoSpacesPopupIcon} alt="Popup Content" class="cisco-spaces-popup-overlay-image" />
                  <div className="cisco-spaces-popup-overlay-close" onClick={() => setCiscoSpacesPopup(false)}>
                    <svg width="44" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_11306_4187)">
                        <circle cx="22.5477" cy="22.1922" r="21.1342" stroke="white" stroke-width="0.98722" />
                        <path d="M29.4141 26.9647L24.6432 22.1929L29.4141 17.4216L27.3198 15.3271L22.5486 20.0983L17.7775 15.3271L15.683 17.4216L20.4544 22.1929L15.6821 26.9647L17.7764 29.0589L22.5486 24.287L27.3198 29.0589L29.4141 26.9647Z" fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_11306_4187">
                          <rect width="43.2556" height="43.2556" fill="white" transform="translate(0.919922 0.564453)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>

              </div>
            </div>
          )}
          <FooterContainer
            setShowSearch={setShowSearch}
            handleResetView={handleResetViewButtonInitialZoom}
            long={long}
            lat={lat}
            rotation={rotation}
            pitch={pitch}
            bearing={bearing}
            whereAmIBuilding={parseInt(whereAmIBuilding)}
            whereAmILevel={parseInt(whereAmILevel)}
          />
        </>
      )}
    </div>
  );
};

export default CiscoLiveMainContainer;

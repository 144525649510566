import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMap } from '../../../context/MapContext';
import { calculateMidPoint } from '../main-container/markCurrentPosition';
import { fetchApis, initiateSdk, manageLayers, mapClicked, poiSelectedBySearch, changeNavigationDescription, highlightFloor ,postQRScanMetric} from './helper';
const AndroidMapComponent = ({ showPopup }) => {
  const { setMap } = useMap();
  const mapBoxRef = useRef(null);
  const pointrWebRef = useRef(); // Store the Pointr SDK instance
  const mapViewControllerWebRef = useRef(null); // Store the Pointr SDK instance
  const location = useLocation(); // Get the current location
  const [sdkLoaded, setSdkLoaded] = useState(false);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const kioskFid = query.get('kioskFid');

  useEffect(() => {
    const checkSdkAvailability = () => {
      if (window.PointrWebSDK) {
        setSdkLoaded(true);
      } else {
        setTimeout(checkSdkAvailability, 100); // Check again after 100ms
      }
    };

    checkSdkAvailability();
  }, []);


  // Function to create and show the loader
  function showLoader() {
    // Create and style the overlay element
    const overlay = document.createElement("div");
    overlay.id = "android-overlay"; // Set the id
    overlay.className = "android-overlay"; // Set the class name
    document.body.appendChild(overlay);

    // Create and style the loader element
    const loader = document.createElement("div");
    loader.id = "android-loader"; // Set the id
    loader.className = "android-loader"; // Set the class name
    document.body.appendChild(loader);

    // Display the loader and overlay
    if (loader && overlay) {
      overlay.style.display = "block";
      loader.style.display = "block";
    }
  }


  // Function to hide and remove the loader
  function hideLoader() {
    const loader = document.getElementById("android-loader");
    const overlayloader = document.getElementById("android-overlay");

    if (loader && overlayloader) {
      loader.style.display = "none";
      document.body.removeChild(loader);

      overlayloader.style.display = "none";
      document.body.removeChild(overlayloader);

    }
  }
  useEffect(() => {
    if (!sdkLoaded) return;
    let expressEnvironment = "spaces-us";

    let handleEnvironments = (expressEnvironment, isMobile) => {
      if (expressEnvironment === "spaces-us") {
        const elem = document.getElementsByClassName("pointr-loading-poster");
        if (elem.length > 0) {
          if (isMobile) {
            elem[0].src = `${process.env.REACT_APP_BACKEND_API_URL}/img/splash-screen-android.png`

          } else {
            elem[0].src = `${process.env.REACT_APP_BACKEND_API_URL}/img/loadingposter.png`

          }
        }
        const elem2 = document.getElementsByClassName("pointr-logo");
        if (elem2.length > 0) { // Check if the element exists
          for (let i = 0; i < elem2.length; i++) {
            elem2[i].style.display = "none"; // Hide the element
          }
        }
      }
    };
    function initiateData(clientDetails) {
      const width =
        window.innerWidth > 0 ? window.innerWidth : window.screen.width;
      const isMobile = width <= 480;

      handleEnvironments(expressEnvironment, isMobile);

      const pointrWeb = initiateSdk(clientDetails.host, clientDetails.clientKey, clientDetails.licenseKey)
      const uiController = pointrWeb.getUiController()
      const mapViewController = uiController.getMapViewController()
      const mapView = mapViewController.getView()
      const navigationViewController = uiController.getNavigationViewController()
      const searchViewController = uiController.getSearchViewController()
      const poiCardViewController = uiController.getPoiCardsViewController()

      const searchViewEvents = searchViewController.getView().events
      const mapEvents = mapView.events
      const poiCardViewEvents = poiCardViewController.getView().events;
      const navigationViewEvents = navigationViewController.getView().events;




      const levelChangeCallback = (newLevel) => {
        console.log("***Map level changed to ", newLevel);
        manageLayers(mapView, newLevel,uiController.getCurrentBuilding().buildingInternalIdentifier)
      };

      const mapClickedCallback = (features) => {
        console.log("***Map level changed to ", features);
        mapClicked(mapView, mapViewController, pointrWeb, navigationViewController, features)
      };


      const searchPoiSelected = (poiFid) => {
        console.log("***Map level changed to ", poiFid);
        poiSelectedBySearch(poiFid, mapViewController, mapView)
        // mapClicked(mapView, mapViewController, pointrWeb, navigationViewController, features)
      };


      const navigationViewShown = (data) => {
        changeNavigationDescription()
    }

      const mapReadyCallback = (data) => {
        mapView.on(mapEvents.mapReady, async () => {
          postQRScanMetric(pointrWeb,clientDetails);
          if (clientDetails.poiId) {
            // Show the loader
            showLoader();
            setTimeout(async () => {
              var myDestPoi = await pointrWeb.getPoiManager().get(clientDetails.poiId);
              var startPoi = await pointrWeb.getPoiManager().get(kioskFid);

              if (myDestPoi&&!kioskFid)
                pointrWeb.highlight(myDestPoi)

              if(kioskFid){
                document.getElementById("pointr-category-list").style.display = 'none'
                navigationViewController.populate(startPoi,myDestPoi);
                navigationViewController.startNavigation();
              }

              hideLoader(uiController);
            }, 10000);
            console.log("***Map Poi changed to ", data);

          } else {
            hideLoader(uiController);
            try {

              switch (clientDetails.actionType) {
                case "site": {
                  console.log("***Action type is 'site' ");
                  mapViewController.updateCurrentSite(clientDetails.locationId);
                }
                  break;
                case "building": {
                  console.log("***Action type is 'building' ");
                  mapViewController.updateCurrentBuilding(clientDetails.locationId);
                }
                  break;
                case "floor": {
                  console.log("***Action type is 'floor' & 'lvlIndex' is ", clientDetails.lvlIndex);
                  highlightFloor(uiController, mapView, clientDetails.lvlIndex)

                }
                  break;

              }

            } catch (error) {
              console.error('Fetch error:', error);
            }
          }

        })


      };

      pointrWeb.on(pointrWeb.events.dataLoaded, mapReadyCallback)
      mapView.on(mapEvents.levelChanged, levelChangeCallback)
      mapView.on(mapEvents.mapClicked, mapClickedCallback)
      searchViewController.getView().on(searchViewEvents.poiSelected, searchPoiSelected)
      poiCardViewController.getView().on(poiCardViewEvents.poiSelected, searchPoiSelected)
      navigationViewController.getView().on(navigationViewEvents.viewShown, navigationViewShown)
      navigationViewController.getView().on(navigationViewEvents.startSelected, navigationViewShown)
      navigationViewController.getView().on(navigationViewEvents.destinationSelected, navigationViewShown)
   

    }



    const fetchData = async () => {
      // Get the current path and extract the last segment
      const path = location.pathname;
      const shortCode = path.substring(path.lastIndexOf('/') + 1);

      // Replace the shortcode in the URL with the last segment
      const url = `${process.env.REACT_APP_QR_CODE_BACKEND_API_URL}/wayfinder/api/${shortCode}/details`;

      try {
        let apiResponse = fetchApis(shortCode)
          .then(result => {
            if (result.success) {
              console.log('Data fetched successfully:', result.data);
              result.data.shortCode=shortCode;
              initiateData(result.data)
            } else {

              console.log(result);
              window.alert("Invalid shortCode provided/ shortcode expired");
              throw new Error('Network response was not ok');

            }
          });
      } catch (error) {
        console.error('Fetch error:', error);
      }
    };




/**
        
     

        const uiController = pointrWebRef.current.getUiController();
        const mapViewController = uiController.getMapViewController();
        const mapView = mapViewController.getView();
        const events = mapView.events;
        const navigationViewController = pointrWebRef.current.getUiController().getNavigationViewController();
        const navigationView = navigationViewController.getView();

        mapViewControllerWebRef.current = mapViewController;

        


        pointrWeb.on(pointrWeb.events.dataLoaded, () => {
          mapView.on(events.mapClicked, async function (features) {
            pointrWeb.getUiController().getCategoryListViewController().hideView();
            mapViewController.removeAllCustomPointMarkersFromMap();
            mapView.setLayoutProperty("rmaps-symbol_selected_ptr", 'icon-image', 'green_marker');
            const priorityList = ['rmaps-booth-marker', 'rmaps-spaces', 'rmaps-symbol_landmark', 'rmaps-hub-theatre-cover', 'rmaps-event-other-marker', 'rmaps-other-covers', 'rmaps-all-other-items', 'rmaps-zone-marker', 'rmaps-zone-cover'];
            let filteredFeatures = features.sort((a, b) => {
              const indexA = priorityList.indexOf(a.layer.id);
              const indexB = priorityList.indexOf(b.layer.id);

              if (indexA === -1) return 1;
              if (indexB === -1) return -1;

              return indexA - indexB;
            });

            for (let i = 0; i < filteredFeatures.length; i++) {
              const feature = filteredFeatures[i];
              try {
                if (feature.properties.fid && feature.properties.name) {
                  let valueAfterTilde;
                  if (feature.properties.fid.includes('~')) {
                    valueAfterTilde = feature.properties.fid.split('~')[1];
                  } else {
                    valueAfterTilde = feature.properties.fid;
                  }
                  const poi = await pointrWeb.getPoiManager().get(valueAfterTilde);
                  if (poi) {
                    if (feature.layer.id === 'rmaps-zone-marker' || feature.layer.id === 'rmaps-zone-cover' || feature.layer.id === 'rmaps-spaces') {
                      const latLong = calculateMidPoint(poi.geometry.coordinates[0]);
                      mapView.map.flyTo({
                        center: [latLong.lng, latLong.lat],
                        zoom: 20, speed: 0.4
                      });
                    } else {
                      mapViewController.highlightPoi(poi);
                      pointrWeb.showPoiDetails(poi);
                    }
                    if (pointrWeb.getUiController().getNavigationViewController().getView().isViewVisible()) {
                      pointrWeb.hidePoiDetails();
                      if (pointrWeb.getUiController().getNavigationViewController().startObject) {
                        pointrWeb.unhighlight(pointrWeb.getUiController().getNavigationViewController().startObject);
                      }
                      mapViewController.highlightPoi(pointrWeb.getUiController().getNavigationViewController().destinationObject);
                      pointrWeb.getUiController().getNavigationViewController().setStartObject(poi);
                      mapViewController.highlightPoi(pointrWeb.getUiController().getNavigationViewController().startObject);
                    }
                    break;
                  }
                }
              } catch (error) {
                console.error('Fetch error:', error);
              }
            }
          });
        });


        setMap(pointrWeb);

        return () => {
          pointrWeb.destroy(); // Cleanup
        };
      } catch (error) {
        console.error('Fetch error:', error);
      }
    };

   
     
   */ fetchData();

  }, [sdkLoaded, location, setMap]);

  return (
    <div id="pointr-container" className="pointr-container">
    </div>
  );
};

export default AndroidMapComponent;

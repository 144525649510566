import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMap } from '../../../context/MapContext';
import { markCurrentPosition, calculateMidPoint } from './markCurrentPosition';
import { updateLayerFilterForSingleFeature, resetBoothLayerFilter } from './layerUtils';
import { fetchSessionDetails } from './searchApis';
import { getStartAndEndOfDayInUTC } from './DateTimeUtils';
import { sendTrackingEventForMapClick,sendTrackingEventForZoomChange,sendTrackingEventForMapMoveEnd } from './pendoApis';
const MapComponent = ({ showPopup, eventName, whereAmILevel, whereAmIBuilding, defaultBuilding, defaultLevel }) => {
  const { setMap } = useMap();
  const mapBoxRef = useRef(null);
  const pointrWebRef = useRef(); // Store the Pointr SDK instance
  const mapViewControllerWebRef = useRef(null); // Store the Pointr SDK instance

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const lat = query.get('lat');
  const long = query.get('long');
  const rotation = query.get('rotation');
  const pitch = query.get('pitch') ? query.get('pitch') : 60;
  const bearing = query.get('bearing') ? query.get('bearing') : -70;
  const tenantId = "26325";
  const locationId = "89468032-5da2-4204-bb90-4da339e35f1e";

  const handleLayerUpdate = async (mapView, feature) => {
    updateLayerFilterForSingleFeature(mapView, feature);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        window.pendo.initialize({
          visitor: {
            id: "user_events.ciscospaces.io"

          },
          account: {
            id: "account_events.ciscospaces.io"

          }
        });

      
        const tenantId = "26325";
        const locationId = "f0f76e00-80c5-458d-8254-ec905e6c7fbd";
        const reqBody = JSON.stringify({ tenantId, locationId });
        const url = `${process.env.REACT_APP_BACKEND_API_URL}/wayfinder/api/v8/generate/client-id`;
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        };

        const response = await fetch(url, {
          method: 'POST',
          headers,
          body: reqBody,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const clientKey = data.result.client_key;

        const options = new window.PointrWebSDK.Options({
          container: "pointr-container",
          apiUrl: `${process.env.REACT_APP_BACKEND_API_URL}/wayfinder`,
          clientInternalIdentifier: clientKey,
          clientSecret: "ce0391b5-7a6b-4e46-90bf-f9739186ad69",
          center: [-115.17791907047695, 36.087886474666035],
          initialZoomLevel: 17.7,
          mapMinZoom: 17.7,
          mapMaxZoom: 23,
          shouldEnableSearch: false,
          shouldEnableZoomControls: false,
          siteExternalIdentifier: "a4eba1e5-3350-4545-9469-d8c920e1e349"
        });

        const pointrWeb = new window.PointrWebSDK.MapWidget(options);
        pointrWeb.start();
        pointrWebRef.current = pointrWeb;

        const uiController = pointrWeb.getUiController();
        const mapViewController = uiController.getMapViewController();
        const mapView = mapViewController.getView();
        const events = mapView.events;
        const navigationViewController = pointrWeb.getUiController().getNavigationViewController();
        const navigationView = navigationViewController.getView();

        mapViewControllerWebRef.current = mapViewController;

        mapView.on(events.mapReady, (data) => {
          let pointrLayers = [];
          mapView.getAllLayers().filter((layer) => {
            if (layer.id.includes("_ptr")) {
              pointrLayers.push(layer);
            }
          });
          mapView.on(events.levelChanged, (newlevel) => {
            mapView.getAllLayers().filter(function (layer) {
              let filter = layer.filter;
              if (filter) {
                let lvlFilter = filter.pop();
                let bidFilter = filter.pop();
                if (!Array.isArray(bidFilter) || (!(bidFilter.includes("bid") && lvlFilter.includes("==")))) {
                  filter.push(bidFilter);
                }
                if (!(lvlFilter.includes("lvl") && lvlFilter.includes("=="))) {
                  filter.push(lvlFilter);
                } else {
                  return layer.id;
                }
              }
            }).forEach(function (layer) {
              layer.filter.push(["==", "lvl", newlevel]);
              if (layer.id.includes("-highlight")) {
                layer.layout.visibility = "none";
              } else if (!layer.id.includes("-selected")) {
                layer.layout.visibility = "visible";
              }
              mapView.removeLayer(layer["id"]);
              mapView.addLayer(layer);
            });
            pointrLayers.forEach((layer) => {
              mapView.removeLayer(layer["id"]);
              layer.layout.visibility = "visible";
              mapView.addLayer(layer);
            });
            mapView.map.flyTo({
              center: [-115.17791907047695, 36.087886474666035
              ],
              zoom: 17.7, pitch: pitch, bearing: bearing, speed: 0.4,
              curve: 1.42, // Adjust the curve (default is 1.42)
              easing: (t) => t, // Optional: customize the easing function
            });
            markCurrentPosition(pointrWeb, long, lat, rotation, false, pitch, bearing, whereAmIBuilding, whereAmILevel, defaultBuilding, defaultLevel);

          });
        });


        mapView.on(mapView.events.mapReady, () => {
          mapViewController.setBearing(bearing);
          mapViewController.setPitch(pitch);
          mapView.map.flyTo({
            center: [-115.17791907047695, 36.087886474666035
            ],
            zoom: 17.7, pitch: pitch, bearing: bearing, speed: 0.4
          });
          markCurrentPosition(pointrWeb, long, lat, rotation, false, pitch, bearing, whereAmIBuilding, whereAmILevel, defaultBuilding, defaultLevel);
          /**
           * const latlng = new window.PointrWebSDK.LatLng({ lat: lat, lng: long });
           */


          // mapView.map.dragPan.disable();

          const bounds = [
            [-115.1821635388674,
            36.08510540329566 ],
            [ -115.1747100326938,
            36.09090737173406]
            ]
            mapView.map.setMaxBounds(bounds);

        });

        const startDestination = new window.PointrWebSDK.Feature(
          { lvl: 1 },
          { type: "Point", coordinates: [long || -115.1782890405586, lat || 36.088308032921276] }
        );

        pointrWeb.on(pointrWeb.events.dataLoaded, () => {
          mapView.on(events.mapClicked, async function (features) {
            showPopup(null, null, true);
            mapViewController.removeAllCustomPointMarkersFromMap();
            mapView.setLayoutProperty("rmaps-symbol_selected_ptr", 'icon-image', 'green_marker');
            const priorityList = ['rmaps-booth-marker', 'rmaps-spaces', 'rmaps-symbol_landmark', 'rmaps-hub-theatre-cover','rmaps-theatre-marker','rmaps-theatre-covers', 'rmaps-event-other-marker', 'rmaps-other-covers', 'rmaps-all-other-items', 'rmaps-zone-marker', 'rmaps-zone-cover'];
            let filteredFeatures = features.sort((a, b) => {
              const indexA = priorityList.indexOf(a.layer.id);
              const indexB = priorityList.indexOf(b.layer.id);

              // Handle cases where layer.id is not in priorityList
              if (indexA === -1) return 1;
              if (indexB === -1) return -1;

              return indexA - indexB;
            });

            for (let i = 0; i < filteredFeatures.length; i++) {
              const feature = filteredFeatures[i];
              try {
                if (feature.properties.fid && feature.properties.name) {
                  let valueAfterTilde;
                  if (feature.properties.fid.includes('~')) {
                    valueAfterTilde = feature.properties.fid.split('~')[1];
                  } else {
                    valueAfterTilde = feature.properties.fid;
                  }
                  const poi = await pointrWeb.getPoiManager().get(valueAfterTilde);
                  if (poi) {
                   
                    //feature.properties.isSelected=true;        
                    if (feature.layer.id === 'rmaps-zone-marker' || feature.layer.id === 'rmaps-zone-cover' || feature.layer.id === 'rmaps-spaces') {

                      const latLong =  (poi.geometry.type=='Point')? {
                        lat: poi.geometry.coordinates[1],
                        lng: poi.geometry.coordinates[0]
                      }:calculateMidPoint(poi.geometry.coordinates[0]);
                      mapView.map.flyTo({
                        center: [latLong.lng, latLong.lat],
                        zoom: 21.5, pitch: pitch, bearing: bearing, speed: 0.4
                      });
                    } else {


                      // handleLayerUpdate(mapView, feature);

                      pointrWeb.unhighlight();
                      mapViewController.highlightPoi(poi);
                    }

                    // Example usage for 27th, 28th, and 29th August 2024 relative to today

                    const startEndEpochTimes = getStartAndEndOfDayInUTC();
                    console.log(startEndEpochTimes);
                    const sessionsInfo = await fetchSessionDetails( tenantId ,valueAfterTilde, { startEpochMilli: startEndEpochTimes.startEpochMilli, endEpochMilli: startEndEpochTimes.endEpochMilli});
                    if (sessionsInfo.length > 0) {
                      poi._properties.isSession = true;
                      poi._properties.sessionsInfo = sessionsInfo;
                    }

                    showPopup(poi,  (poi?.geometry?.type=='Point')?poi.geometry.coordinates :calculateMidPoint(poi.geometry.coordinates[0]));
                    sendTrackingEventForMapClick(feature, eventName);
                    //createCustomPopup(mapViewController, feature, calculateMidPoint(poi.geometry.coordinates[0]));
                    //navigationViewController.populate(startDestination, poi);
                    //navigationViewController.startNavigation();
                    break;
                  }
                }
              } catch (error) {
                console.error('Fetch error:', error);
              }



            }
            // mapViewController.updateFeatures(mapView.getLayer("rmaps-booth-marker"),filteredFeatures);
          });
        });

        pointrWeb.on(pointrWeb.events.dataLoaded, () => {
          mapView.on(events.zoomChanged,(zoomValue) => {
            sendTrackingEventForZoomChange(zoomValue.toFixed(1), eventName);
          })
        });

        pointrWeb.on(pointrWeb.events.dataLoaded, () => {
          mapView.on(events.mapMoveEnd,() => {
            //sendTrackingEventForMapMoveEnd(eventName);
            console.log(" Call back mapMoveEnd ");
          })
        });
        setMap(pointrWeb);

        return () => {
          pointrWeb.destroy(); // Cleanup
        };
      } catch (error) {
        console.error('Fetch error:', error);
      }
    }

    fetchData();
  }, [lat, long, rotation, setMap]);



  /*
    // Create the popup and add it to the DOM
  
    function createCustomPopup(mapViewController, feature, lngLat) {
      mapViewController.removeAllMarkers();
       
      const customElement = document.createElement("div");
      customElement.className = "marker";
      customElement.style.backgroundImage = "url(https://pointrapps.blob.core.windows.net/core-web-sdk/sdk-v8/icons/64x64-pamuk.jpg)";
      customElement.style.width = "64px";
      customElement.style.height = "64px";
      // ADD click event to your element
      customElement.addEventListener("click", function () {
        window.alert("Marker clicked");
      });
  
      const popupContent = document.createElement('div');
      popupContent.className = 'poi-popup';
  
      const title = document.createElement('h3');
      title.className = 'poi-popup-title';
      title.textContent = feature.properties.name;
  
      const description = document.createElement('p');
      description.className = 'poi-popup-description';
      description.textContent = feature.properties.description;
  
      const button = document.createElement('button');
      button.className = 'poi-popup-button';
      button.textContent = 'Get Directions';
      button.onclick = () => alert('More Info');
  
      const close = document.createElement('button');
      close.className = 'poi-popup-button';
      close.textContent = 'Get Directions';
      close.onclick = () => mapViewController.removeMarker(myMarker);
  
  
      // Append the elements to the popup content
      popupContent.appendChild(title);
      popupContent.appendChild(description);
      popupContent.appendChild(button);
  
  
  
      const myMarkerFeature = new window.PointrWebSDK.Feature(
        {},
      { type: "Point", coordinates: [lngLat.lng,lngLat.lat] }
      );
  
      const myMarker = mapViewController.addMarker(myMarkerFeature, popupContent);
  
  
      // Add event listener to close the marker when clicking outside
      document.addEventListener('click', handleClickOutside(mapViewController));
      // Create a new popup instance
      //  const popup = mapViewController.getView().addPopup(feature,  '<h3 style="font-size:16px;font-weight:bold;">${feature.properties.name}</h3>', { offset: 1 });
  
      // Optionally, you can add custom classes to the popup for styling
      // popup.getElement().classList.add('poi-popup-container');
      // popup.popup.addClassName('poi-popup-container')
    }
  
    function handleClickOutside(mapViewController) {
      mapViewController.removeAllMarkers();
  
    }*/

  return (
    <div id="pointr-container" className="pointr-container">
    </div>
  );
};

export default MapComponent;

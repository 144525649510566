import React, { useEffect, useState, useRef } from "react";
import MapComponent from "../common-pointr-map-container/GenericPointrMapContainer"
import LeftContainer from "../left-container/left-container";
import HeaderContainer from "../header-container/header-container";
import RightContainer from "../right-container/right-container";
import SearchPopup from '../main-container/SearchPopup'; // Import SearchPopup component
import FooterContainer from '../footer-container/footer-container';
import { useMap } from '../../../context/MapContext'; // Import the useMap hook
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import { handleReset } from '../main-container/handleResetView'; // Import the functions
import { calculateMidPoint, manageLayers } from '../main-container/markCurrentPosition'; // Import the functions
import { fetchGsxData } from '../left-container/fetchZonesHubwalksInfo';
import CustomPopup from '../main-container/DirectionPopup';
import ciscoSpacesPopupIcon from '../../../../assets/images/powered-by-spaces-cl.png';
import Screensaver from "../countdown-timer-container/screensaver";
import ciscoSpacesPopupImage from '../../../../assets/images/cisco-space-popup-cl.png';
import {handleWhereAmI} from '../footer-container/handle-where-am-i';  



const CiscoLiveEMEAMainContainer = () => {
  const kioskData = {
  };

  const { map } = useMap();

  const [isActive, setIsActive] = useState(true);
  const [timer, setTimer] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [isZonesInfoPopulated, setIsZonesInfoPopulated] = useState(false);

  const [zonesInfo, setZonesInfo] = useState([]);
  const [hubwalksInfo, setHubwalksInfo] = useState([]);
  const [facilitiesData, setFacilitiesData] = useState([]);
  const hasPassedDestFidRef = useRef(false);

  const timerRef = useRef(null);
  const [popupState, setPopupState] = useState({ poi: null, coordinates: null }); // Add state for the popup
  const [ciscoSpacesPopup, setCiscoSpacesPopup] = useState(false);
  const [isPointrSDKLoaded, setIsPointrSDKLoaded] = useState(false); // State to track Pointr SDK load status
  const [eventStartDateData, setEventStartDateData] = useState({
    year: 2025,
    month: 1,
    day: 9
  });


  const [eventEndDateData, setEventEndDateData] = useState({
    year: 2025,
    month: 1,
    day: 14
  });

  const tenantId = "263253";
  const locationId = "d4ac6990-d57f-4533-8904-158ccd2794c5";
  const siteExternalIdentifier = "f2a0ba12-1e7f-4605-8568-aed54c4f8974";
  const timeZoneCity = "Amsterdam";
  const timeZone = "Europe/Amsterdam";
  const qrCodeExpiresAt = 1735689598
  const eventLogoName = "cisco-live.svg"
  const eventName = "CLEMEA"
  const eventTitle = "Amsterdam"
  const defaultBuildingTitle = "Amsterdam"

  
  const eventSubTitle = ""

  const buildingInfo = [
    {
      "buildingTitle": "Amsterdam 2025",
      "buildingInternalIdentifier": 8,
      "buildingExternalIdentifier": "d4ac6990-d57f-4533-8904-158ccd2794c5",
      "buildingExtraData": {
        "timeZone": "Europe/Amsterdam"
      },
      "geometry": {
          "type": "Polygon",
          "coordinates": [
              [
                  [
                      4.88471239,
                      52.34492802
                  ],
                  [
                      4.89179673,
                      52.3450605
                  ],
                  [
                      4.89223047,
                      52.33828851
                  ],
                  [
                      4.88423047,
                      52.33849463
                  ],
                  [
                      4.88471239,
                      52.34492802
                  ]
              ]
          ]
      },
      "levels": [
          {
              "levelShortTitle": "GF",
              "levelLongTitle": "Ground Floor",
              "levelIndex": 0,
              "levelExtraData": {
              "timeZone": "Europe/Amsterdam",
              "levelExternalIdentifier": "bae17f92-ca21-4dba-808d-60426abdaac5"
          }
          },
          {
              "levelShortTitle": "L1",
              "levelLongTitle": "Level 1",
              "levelIndex": 1,
              "levelExtraData": {
              "timeZone": "Europe/Amsterdam",
              "levelExternalIdentifier": "8930d9b7-9cd9-408b-90ed-525b5ccc998f"
          }
          }
      ]
  }
];
  // Or, if the keys are static, you can use an object:
  const buildingExID = {
    8: "d4ac6990-d57f-4533-8904-158ccd2794c5",

  };
  const levelExId ={
    "d4ac6990-d57f-4533-8904-158ccd2794c5":{
      0:"bae17f92-ca21-4dba-808d-60426abdaac5",
      1:"8930d9b7-9cd9-408b-90ed-525b5ccc998f"
    }
  }

  const minMaxBounds = [
    
    [ 4.874747373780906,
      52.32649719537784
    ],
    [
      4.900164974144076,
      52.35777235234855
    ]
  ];
 
  const getLocationId = (poi) => {
    const bid = poi?.properties?.bid;
    const lvl = poi?.properties?.lvl;
    const buildingId = buildingExID[bid];
  
    // Check if both buildingId and lvl exist in levelExId
    if (buildingId && lvl !== undefined && levelExId[buildingId]?.[lvl] !== undefined) {
      return levelExId[buildingId][lvl];
    }
  
    // Fallback to buildingId if lvl is not present or no match found
    return buildingId || locationId;
  };
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const kioskFid = query.get('kioskFid');
  const destFid = query.get('destFid');
  const latFromQuery = parseFloat(query.get('lat'));
  const longFromQuery = parseFloat(query.get('long'));
  const rotationFromQuery = parseFloat(query.get('rotation'));
  const zoomFromQuery = parseFloat(query.get('zoom'));
  const pitchFromQuery = parseFloat(query.get('pitch'));
  const bearingFromQuery = parseFloat(query.get('bearing'));
  const levelFromQuery = parseFloat(query.get('level'));
  const buildingFromQuery = parseFloat(query.get('building'));
  const centerLong = 4.888230470000053;
  const centerLat = 52.341674505000015;

  const [lat, setLat] = useState(kioskData[kioskFid]?.lat || (latFromQuery ? latFromQuery :52.341674505000015));
  const [long, setLong] = useState(kioskData[kioskFid]?.long || (longFromQuery ? longFromQuery : 4.888230470000053));
  const [rotation, setRotation] = useState(kioskData[kioskFid]?.rotation || (rotationFromQuery ? rotationFromQuery:0));
  const [zoom, setZoom] = useState(kioskData[kioskFid]?.zoom || (zoomFromQuery ? zoomFromQuery : 17));
  const [pitch, setPitch] = useState(kioskData[kioskFid]?.pitch || (pitchFromQuery ? pitchFromQuery: 65));
  const [bearing, setBearing] = useState(kioskData[kioskFid]?.bearing || (bearingFromQuery ? bearingFromQuery : -70));
  const [level, setLevel] = useState(kioskData[kioskFid]?.level || (levelFromQuery ? levelFromQuery: 0));
  const [building, setBuilding] = useState(kioskData[kioskFid]?.building || (buildingFromQuery ? buildingFromQuery : 8));

  const [whereAmILevel, setWhereAmILevel] = useState(level);
  const [whereAmIBuilding, setWhereAmIBuilding] = useState(building);
  const [selectedFloor, setSelectedFloor] = useState(level);

  useEffect(() => {
    if (kioskFid && kioskData[kioskFid]) {
      const data = kioskData[kioskFid];
      setLat(data.lat || 52.341674505000015);
      setLong(data.long ||  4.888230470000053);
      setRotation(data.rotation || -1);
      setZoom(data.zoom || 17);
      setPitch(data.pitch || 65);
      setBearing(data.bearing || -70);
      setLevel(data.level || 0);
      setWhereAmILevel(level);
      setBuilding(data.building || 8);
      setWhereAmIBuilding(building);
    }
  }, [kioskFid]);


  useEffect(() => {
    if (destFid && !hasPassedDestFidRef.current) {
      // Call any function or set up as needed for the first time `destFid` is passed
      hasPassedDestFidRef.current = true;
    }
  }, [destFid]);

  
  const showPopup = (poi, coordinates, cleanPopup = true,isMapClick=false) => {
    if (!poi) {
      if(popupState.poi){
        handleResetView(cleanPopup,isMapClick);
      }
      setPopupState({ poi: null, coordinates: null });
     
    } else {
      setPopupState({ poi, coordinates: (poi?.geometry?.type === 'Point') ? poi.geometry.coordinates : calculateMidPoint(poi.geometry.coordinates[0]) });
    }
  };
  const showCiscoSpacesPopup = () => {
    setCiscoSpacesPopup(true);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.PointrWebSDK) {
        setIsPointrSDKLoaded(true);
        clearInterval(interval);
      }
    }, 100); // Check every 100ms

    return () => clearInterval(interval);
  }, []);
  // Handler to hide the popup
  const hidePopup = () => {
    setPopupState({ poi: null, coordinates: null });
    handleResetView();
  };

  useEffect(() => {
    // Fetch data from the API only once when the component mounts
    const getZones = async () => {
      try {

        const data = await fetchGsxData(tenantId);
        console.log("gsx" + data);
        if(data.zones){
          setIsZonesInfoPopulated(true);
        }
        setZonesInfo(data.zones);
        setHubwalksInfo(data.hubWalks);
        setFacilitiesData(data.facilities);

      } catch (err) {

        console.log(err);

      }
    };

    getZones();
  }, []);

  // Simulate map loading
  useEffect(() => {
    // Simulate a delay for the map loading
    const mapLoadTimeout = setTimeout(() => {
      setIsMapLoaded(true);
      document.title = '2025 Cisco Live';
    }, 5000); // Adjust the delay as needed

    return () => clearTimeout(mapLoadTimeout);
  }, []);

  useEffect(() => {
    let intervalId;
  
    const fetchPoiUntilFound = async () => {
      if (map && destFid) {
        const poi = await map.getPoiManager().get(destFid);
        if (poi) {
          // Uncomment this line when ready to show the popup
          const uiController = map.getUiController();
          const mapViewController = uiController.getMapViewController();
          mapViewController.setLevel(poi.properties.lvl);
          mapViewController.highlightPoi(poi);
           showPopup(poi, poi?.geometry?.type === 'Point' ? poi.geometry.coordinates : calculateMidPoint(poi.geometry.coordinates[0]),false,false);
           clearInterval(intervalId); // Stop checking once the poi is found
        }
        
      }
    };
  
    // Set up polling to run fetchPoiUntilFound every 500ms (adjust as needed)
    intervalId = setInterval(fetchPoiUntilFound, 5000);
  
    return () => clearInterval(intervalId); // Clear interval on component unmount or when dependencies change
  }, [map, destFid]);


  useEffect(() => {
    const eventTypes = ["click", "mousemove", "keypress", "wheel", "gesturestart", "gesturechange", "gestureend", "touchmove", "touchstart", "touchend"];

    eventTypes.forEach(eventType => {
      window.addEventListener(eventType, handleActivity);
    });

    return () => {
      eventTypes.forEach(eventType => {
        window.removeEventListener(eventType, handleActivity);
      });
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => { }, [isActive]);


  const handleActivity = (event) => {
    setIsActive(true);
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setIsActive(false);
      console.log("No activity detected for 2 minutes." + event.type);
    }, 180000); // 2 minutes
  };


  const handleSearch = async (searchInput) => {
    hidePopup();
    const uiController = map.getUiController();
    const mapViewController = uiController.getMapViewController();
    const mapView = mapViewController.getView();
    mapView.setLayoutProperty("rmaps-symbol_selected_ptr", 'icon-image', 'green_marker');

    console.log(searchInput);
    let valueAfterTilde;
    let poiFid = searchInput?.fId;
    if (searchInput?.type === 'session') {
      poiFid = searchInput?.poiFId;

    }
    if (poiFid?.includes('~')) {
      valueAfterTilde = poiFid.split('~')[1];
    } else {
      valueAfterTilde = poiFid;
    }
    const poi = await map.getPoiManager().get(valueAfterTilde);
    if (poi) {
      map.unhighlight();
      mapViewController.setLevel(poi.properties.lvl);
      mapViewController.highlightPoi(poi);
      map.hidePoiDetails(poi);

      if (poi?._geometry?.coordinates[0]) {
        const latLong = (poi.geometry.type === 'Point') ? {
          lat: poi.geometry.coordinates[1],
          lng: poi.geometry.coordinates[0]
        } : calculateMidPoint(poi?._geometry?.coordinates[0]);

        //updateLayerFilterForSingleFeature(mapView, searchInput);
        mapView.map.flyTo({
          center: [latLong.lng, latLong.lat],
          zoom: 20, pitch: pitch, bearing: bearing, speed: 0.4
        });
        if (searchInput.type === 'session') {
          poi._properties.isSession = true;
          poi._properties.sessionsInfo = [searchInput];
          poi._properties.sessionStart= searchInput?.utcStartTime;

        }
        showPopup(poi, latLong);
        manageLayers(map?.getUiController().getMapViewController().getView(),poi?._properties?.bid,poi?._properties?.lvl)
      }
    }
  };

  useEffect(() => {
    if (searchInput) {
      handleSearch(searchInput);
    }
  }, [searchInput]);

  const handleResetView = (cleanPopup = false,isMapClick=false) => {
    handleReset(long ? long : 4.888230470000053, lat ? lat : 52.341674505000015, rotation ? rotation : -4.5, zoom ? zoom : 22, true, cleanPopup,isMapClick, pitch, bearing, whereAmIBuilding, whereAmILevel, centerLong, centerLat);
  };


  const handleResetViewButton = (cleanPopup = false) => {
    setPopupState({ poi: null, coordinates: null });
    handleResetView();
  };

  const handleResetViewButtonInitialZoom = (cleanPopup = false) => {
    setPopupState({ poi: null, coordinates: null });
    handleResetView();

    const uiController = map.getUiController();
    const mapViewController = uiController.getMapViewController();
    const mapView = mapViewController.getView();
    mapView.map.flyTo({
      center: [long, lat],
      zoom: 19.5,
      bearing: bearing,
      pitch: pitch,
      speed:0.4
    });
  };

  return (
    <div className="main-container">

      {isPointrSDKLoaded && <MapComponent showPopup={showPopup} tenantId={tenantId}
        locationId={locationId} siteExternalIdentifier={siteExternalIdentifier} lat={lat} long={long} zoom={zoom} pitch={pitch} bearing={bearing} rotation={rotation} eventName = {eventName} whereAmILevel={parseInt(whereAmILevel)} whereAmIBuilding={parseInt(whereAmIBuilding)} defaultBuilding={parseInt(building)} defaultLevel={parseInt(level)} setBuilding={setBuilding} setLevel={setLevel} timeZone={timeZone} eventStartDateData={eventStartDateData} centerLatLong={[centerLong,centerLat]} minMaxBounds={minMaxBounds}/>}
      {isMapLoaded && (
        
        <>
          {handleWhereAmI(map,long,lat,rotation,pitch,bearing,whereAmILevel, whereAmIBuilding)}
          <HeaderContainer
            showCiscoSpacesPopup={showCiscoSpacesPopup} timeZoneCity={timeZoneCity} 
            eventStartDateData = {eventStartDateData} eventEndDateData={eventEndDateData}  
            handleResetView={handleResetViewButton}  eventLogoName={eventLogoName} 
            eventName={eventName} defaultLevel={parseInt(level)} 
            defaultBuilding = {parseInt(building)} setBuilding={setBuilding} 
            setLevel={setLevel} pitch={pitch} 
            bearing={bearing} lat={lat} long={long} 
            rotation={rotation}
            eventTitle={eventTitle}
            eventSubTitle={eventSubTitle}
            eventTimeZone={timeZone}
            defaultBuildingTitle={defaultBuildingTitle}
            buildingInfo={buildingInfo}/>
          {zonesInfo &&
            <LeftContainer
              zoneData={zonesInfo} showPopup={showPopup} hidePopup={hidePopup} tenantId={tenantId}
              locationId={locationId} qrCodeExpiresAt={qrCodeExpiresAt}
              whereAmIBuilding={whereAmIBuilding}
              whereAmILevel={whereAmILevel}
              defaultLevel={parseInt(level)} 
              defaultBuilding = {parseInt(building)}
              handleResetView={handleResetViewButton}
              setBuilding={setBuilding}
              setLevel={setLevel} 
              lat={lat}
              long={long}
              rotation={rotation}
              showLevelSelector={true}
              isZonesInfoPopulated={isZonesInfoPopulated}
              isDestFidPopulated={destFid?true:false}
              selectedFloor={selectedFloor}
              setSelectedFloor={setSelectedFloor}
              buildingInfo={buildingInfo}
              defaultBuildingTitle={defaultBuildingTitle}
              centerLatLong={[centerLong,centerLat]}
               />}
          {isPointrSDKLoaded && <RightContainer
            hubwalksData={hubwalksInfo} facilitiesData={facilitiesData} pitch={pitch} bearing={bearing} centerLong={centerLong} centerLat={centerLat}/>}
          {(!isActive &&
            /**<CountdownTimer
              initialMinutes={0}
              initialSeconds={10}
              resetTimer={isActive} // Pass isActive as a prop to reset the timer
              showPopup={showPopup}
            />*/
            <Screensaver inactivityTimeout={1000} flipInterval={3000} showPopup={showPopup} ciscoSpacesPopupImage={ciscoSpacesPopupImage} lat={lat} long={long} zoom={zoom} rotation={rotation} pitch={pitch} bearing={bearing} whereAmILevel={whereAmILevel} centerLong={centerLong} centerLat={centerLat}/>

          )}
          {showSearch && (
            <SearchPopup
              setSearchInput={setSearchInput}
              setShowSearch={setShowSearch}
              map={map}
              tenantId={tenantId}
              placeholder={"Search for points of interest and other facilities"}
            />
          )}
          {popupState.poi && (
            <CustomPopup
              poi={popupState.poi}
              coordinates={popupState.coordinates}
              tenantId={tenantId}
              locationId={getLocationId(popupState.poi)}
              onCancel={hidePopup}
              source={"poiClick"}
              lat={lat}
              long={long}
              building={building}
              level={level}
              rotation={rotation}
              bearing={bearing}
              pitch={pitch}
              whereAmIBuilding={whereAmIBuilding}
              whereAmIlevel={whereAmILevel}
              qrCodeExpiresAt={qrCodeExpiresAt}
              destFid={hasPassedDestFidRef.current ? undefined : destFid} 
              setBuilding={setBuilding}
              setLevel={setLevel}
              eventStartDate={eventStartDateData}
              eventTimeZone={timeZone}
              setSelectedFloor={setSelectedFloor}
            />
          )}
          {ciscoSpacesPopup && (
            <div className="cisco-spaces-popup-overlay" >
              <div className="cisco-spaces-popup-overlay">
                <div className="cisco-spaces-popup-overlay-popup">
                  <img src={ciscoSpacesPopupIcon} alt="Popup Content" class="cisco-spaces-popup-overlay-image" />
                  <div className="cisco-spaces-popup-overlay-close" onClick={() => setCiscoSpacesPopup(false)}>
                    <svg width="44" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_11306_4187)">
                        <circle cx="22.5477" cy="22.1922" r="21.1342" stroke="white" stroke-width="0.98722" />
                        <path d="M29.4141 26.9647L24.6432 22.1929L29.4141 17.4216L27.3198 15.3271L22.5486 20.0983L17.7775 15.3271L15.683 17.4216L20.4544 22.1929L15.6821 26.9647L17.7764 29.0589L22.5486 24.287L27.3198 29.0589L29.4141 26.9647Z" fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_11306_4187">
                          <rect width="43.2556" height="43.2556" fill="white" transform="translate(0.919922 0.564453)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>

              </div>
            </div>
          )}
          <FooterContainer
            setShowSearch={setShowSearch}
            handleResetView={handleResetViewButtonInitialZoom}
            long={long}
            lat={lat}
            rotation={rotation}
            pitch={pitch}
            bearing={bearing}
            whereAmIBuilding={parseInt(whereAmIBuilding)}
            whereAmILevel={parseInt(whereAmILevel)}
          />
        </>
      )}
    </div>
  );
};

export default CiscoLiveEMEAMainContainer;

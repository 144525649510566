
const shortCodeDetailApiPath = "/wayfinder/api/{shortCode}/details"


export function formatString(template, values) {
    return template.replace(/{(\w+)}/g, (match, key) => values[key] || "");
}

export function changeNavigationDescription() {
    document.getElementById("pointr-navigation-description").innerHTML = "To get directions to your destination, enter the starting point (look around and find the nearest booth). <br/> The full wayfinding experience with blue dot is available with iOS devices."
}

export function calculateMidPoint(coordinates) {
    if (coordinates.length === 0) return null;

    let firstCoordinate = coordinates[0];
    let minX = firstCoordinate[0];
    let maxX = firstCoordinate[0];
    let minY = firstCoordinate[1];
    let maxY = firstCoordinate[1];

    coordinates.forEach(coordinate => {
        minX = coordinate[0] < minX ? coordinate[0] : minX;
        maxX = coordinate[0] > maxX ? coordinate[0] : maxX;
        minY = coordinate[1] < minY ? coordinate[1] : minY;
        maxY = coordinate[1] > maxY ? coordinate[1] : maxY;
    });

    return {
        lat: (minY + maxY) / 2,
        lng: (minX + maxX) / 2
    };
}



export function setZoomLevel(poi, mapView) {
    let zoomLevel = 20
    if(poi.properties.typeCode === "section") {
        zoomLevel = 19.5
    }
    const latLong = calculateMidPoint(poi.geometry.coordinates[0]);
    mapView.map.flyTo({
      center: [latLong.lng, latLong.lat],
      zoom: zoomLevel, speed: 0.4
    });
}

export async function fetchApis(shortCode) {
    let url = `${process.env.REACT_APP_QR_CODE_BACKEND_API_URL}/wayfinder/api/${shortCode}/details`
    try {
        const response = await fetch(url);
        
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        
        const data = await response.json();
        
        // Process the data or return a success message
        return { success: true, data: data.result }; // or return a success message
    } catch (error) {
        // Handle errors here
        return { success: false, message: error.message };
    }
}

export function initiateSdk(host, clientKey, licenseKey) {
    const options = new window.PointrWebSDK.Options({
        container: "pointr-map",
        apiUrl: host+"/wayfinder",
        clientInternalIdentifier: clientKey,
        clientSecret: licenseKey,
        shouldEnableZoomControls: false,
        mapMinZoom: 17,
        mapMaxZoom: 22,
        initialZoomLevel: 18
        
    });
    const pointrWeb = new window.PointrWebSDK.MapWidget(options);
    pointrWeb.start();
    return pointrWeb
}

export function manageLayers(mapView, newlevel, newBid) {
    let rMapLayers = mapView.getAllLayers().filter(item => item.id.startsWith('rmaps-'))
    let quickLayers =  mapView.getAllLayers().filter(item => item.id.startsWith('symbol_quick-access'))
    const combinedLayers = rMapLayers.concat(quickLayers)
    combinedLayers.forEach(layer => {
        if (layer.source !== "visualizer") {
            let filters = layer.filter
            console.log(layer.id, filters)

            if (filters) {
                let lastFilter = filters.pop();

                if (!(lastFilter.includes("lvl") && lastFilter.includes("=="))) {
                    filters.push(lastFilter);
                } else {
                 /** 
                    let buildingFilter =  filters.pop();
                    if (!(buildingFilter.includes("bid") && lastFilter.includes("=="))) {
                        filters.push(buildingFilter);
                    }
                  filters.push(["==", "bid", newBid])*/ 
             
                    filters.push(["==", "lvl", newlevel])
                }
            }
            mapView.removeLayer(layer["id"])
            mapView.addLayer(layer)
        }
    });
}


export async function mapClicked(mapView, mapViewController, pointrWeb, navigationViewController, features) {
    if (navigationViewController.isNavigationActive) {
        return
    }
    for (const feature of features) {
        if (feature.properties.fid && feature.properties.name) {

           let destPoi = navigationViewController.destinationObject
           let startPoi = navigationViewController.startObject
          
            const poi = await pointrWeb.getPoiManager().get(feature.properties.fid)
          
            if (poi) {
                mapViewController.unhighlightAllPois();
                mapViewController.clearCachedHighlightedPois();
                if (destPoi === poi) {
                    destPoi = poi
                } else {
                    if (destPoi === null) {
                        destPoi = poi
                    } else {
                        startPoi = poi
                    }
                }
                pointrWeb.unhighlight();
                mapViewController.removeAllCustomPointMarkersFromMap();

                mapViewController.highlightPoi(destPoi);
                if (startPoi) {
                    mapViewController.highlightPoi(startPoi);
                }
                navigationViewController.populate(startPoi, destPoi);
                
                if (!destPoi  || !startPoi) {
                    setZoomLevel(poi, mapView)
                }
                document.getElementById("pointr-category-list").style.display = 'none'
                changeNavigationDescription();
                break;
            } 
        }
    }
}

export async function postQRScanMetric(pointrWeb,clientDetails) {
    let url = `${process.env.REACT_APP_BACKEND_API_URL}/wayfinder/api/metric/message`
    try {
        let deviceId = pointrWeb.analyticsManager.deviceId;
        if (deviceId) {
            try {
                // Create the payload
                const payload = {
                    shortCode: clientDetails.shortCode, // Include shortCode from clientDetails
                    deviceId: deviceId,                  // Use the retrieved deviceId
                    clientId: clientDetails.clientKey,     // Include clientKey from clientDetails
                    poiId: clientDetails.poiId || null    // Include poiId, default to null if not present
                };
                const response = await fetch(url, {
                    method: 'POST', // Use POST method
                    headers: {
                        'Content-Type': 'application/json', // Specify content type as JSON
                        'Authorization': pointrWeb.dataManager.networkCoordinator.apiToken
                    },
                    body: JSON.stringify(payload), // Send the clientDetails as JSON
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }

                const data = await response.json();

                // Process the data or return a success message
                return { success: true, data: data.result };
            } catch (error) {
                console.error('Error parsing deviceIds:', error);
            }
        }


    } catch (error) {
        // Handle errors here
        return { success: false, message: error.message };
    }
}

export async function poiSelectedBySearch(poiFid, mapViewController, mapView) {
    let poi = await mapViewController.poiManager.get(poiFid)
    setZoomLevel(poi, mapView)
}

export function highlightFloor(uiController, mapView, levelIndex) {
    let levels;
    mapView.on(mapView.events.currentBuildingChanged, (event) => {
        if (event.newCurrentBuilding !== undefined) {
            levels = event.newCurrentBuilding?.levels;
        }
        const filteredLevels = levels.filter(level => level.levelIndex === levelIndex);
        const levelObject = new window.PointrWebSDK.Level(filteredLevels[0]);

        uiController.setLevel(levelObject);
    });

}
import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { TeaLogService } from '../tealog.service';
import { SurveyActivityOperation } from '../tealog.model';

type SurveyActivityInput = {
  surveyActivityStart: Date, 
  surveyActivityOperation: SurveyActivityOperation,
  surveyedSessionTitle: string,
  surveyedSessionId: string,
} 

/**
 * @whatItDoes Enters a Tealium log record of a survey event generated by a click of a button.
 *
 * @howToUse
 * ```
 * 
 *     <button type="button" [teaLogSurveyActivity]="{surveyActivityStart: dateTime, surveyActivityOperation: SurveyActivityOperation.submission, surveyedSessionTitle: '<Title of the Session>', surveyedSessionId: '<ID of the Session>'}">Next Answer</button>
 *     
 *      or, for activity just started:
 *     <button type="button" [teaLogSurveyActivity]="{surveyActivityOperation: SurveyActivityOperation.submission, surveyedSessionTitle: '<Title of the Session>', surveyedSessionId: '<ID of the Session>'}">Next Answer</button>
 * 
 * ```
 */
@Directive({
  selector: '[teaLogSurveyActivity]'
})
export class TeaLogSurveyActivityDirective {
  
  private surveyActivityMetadata: SurveyActivityInput | any;

  constructor(
    private teaLogService: TeaLogService, 
    private elementRef: ElementRef<HTMLAnchorElement | HTMLButtonElement>){}
  
  @Input('teaLogSurveyActivity')
  set metadata(surveyActivityMetadata: SurveyActivityInput) {
    this.surveyActivityMetadata = surveyActivityMetadata;
  }
  @HostListener('click') onClick() {
    this.teaLogSurveyActionEvent();
  }

  private teaLogSurveyActionEvent(){
    if(this.elementRef.nativeElement instanceof HTMLButtonElement){
      this.teaLogService.teaLogSurveyAction(
        this.surveyActivityMetadata.surveyActivityStart || new Date(),
        this.surveyActivityMetadata.surveyActivityOperation,
        this.surveyActivityMetadata.surveyedSessionTitle,
        this.surveyActivityMetadata.surveyedSessionId,
      ); 
    }
  }
}
import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { TeaLogService } from '../tealog.service';

/**
 * @whatItDoes Enters a Tealium log record of a content download event generated by a click of an anchor tag or a button.
 *
 * @howToUse
 * ```
 *      In Anchor tag, both contentTitle and contentUrl fields are optional 
 *     <a href="location/of/the/file" target="_blank" teaLogContentDownload>Download File</a>
 *     <a href="location/of/the/file" target="_blank" [teaLogContentDownload]="{contentTitle: '<Name of The File>', contentUrl: '<location/of/the/file>'}>Download File</a>
 * 
 *      In Anchor tag, both contentTitle is optional and contentUrl is required
 *     <button type="button" [teaLogContentDownload]="{contentUrl: '<location/of/the/file>'}">Download File</button>
 *     <button type="button" [teaLogContentDownload]="{contentTitle: '<Name of The File>', contentUrl: '<location/of/the/file>'}">Download File</button>
 * 
 * ```
 */
@Directive({
  selector: '[teaLogActivity]'
})
export class TeaLogActivityDirective {
  
  private operation: string | any;

  constructor(
    private teaLogService: TeaLogService, 
    private elementRef: ElementRef<HTMLAnchorElement | HTMLButtonElement>){}
  
  @Input('teaLogActivity')
  set metadata(operation: string) {
    this.operation = operation;
  }
  @HostListener('click') onClick() {
    this.teaLogEvent();
  }

  private teaLogEvent(){
    if(this.elementRef.nativeElement instanceof HTMLButtonElement || HTMLAnchorElement){
      this.teaLogService.teaLogButtonAction(this.operation);
    }
  }
}
// CDC Support Classes
export type TypeUser = {
    eventId: string,
    userKey: string, 
    email: string,
    firstName: string,
    lastName: string,
    country: string,
    attendeeType?: string[],
    reportClassification?: string;
    isTestRecord: string;
    dashboardPackage?: string[];
    jobLevel?: string;
    impact?: {
        prefBadge?: string;
		attendeeType?: string[];
		geoInfo?: string;
		geoAgenda?: string;
		jobRole?: string;
		track?: string;
		marketPriority?: string[];
		category?: string;
    }
}
export const system: TypeUser = {
    eventId: undefined,
    userKey: "001",
    email: "fep-it-team@cisco.com",
    firstName: "System",
    lastName: "System",
    country: "United States of America",
    isTestRecord: "no"
}
export const defaultConfig = {
    "logging": {
        "level": "none",
        "message": {
            "initError": "Local data initialization failure",
            "tracking": "Requested CDC Logging Cannot be Authorized",
            "unresolvedUser": "Missing User Information",
            "unresolvedEvent": "Missing Event Information",
            "unqualifiedUser": "Test users do not qualify for logging"
        }
    },
    "data": {
        "userSessionStorageKey": "user",
        "jwtStorageKey": "authTokenResponse",
        "key": "AIzaSyC8aOz4nMHExWrJGIEGhup3F6__rN0KkIA",
        "nativeTrackingHost": "cisco-events-admin-ienlrwqbua-uc.a.run.app",
        "pubSubTopic": "events-tealium-v1",
        "pubSubProjectId": "gcp-mktgeventsservi-prd-16509"
    },
    "settings": {
        "hidePII": false,
        "allowTestUserTracking": false,
        "nativeTracking": false,
        "cloudTracking": true,
    }
}
export interface ICDC{
    ut: IUT;
}
export interface IUT{
    eventqueue: IEventQueue;
    trackEvent: ITrackEvent;
}
export interface IEventQueue{
    q: any[];
}
export interface ITrackEvent{
    event(a:any, b: any): void;
}
export class CDC implements ICDC{
    public ut: IUT;
    constructor(){
        this.ut = new UT();
    }
}
export class UT implements IUT{
    public eventqueue: IEventQueue;
    public trackEvent: ITrackEvent;
    constructor(){
        this.eventqueue = new EventQueue();
        this.trackEvent = new TrackEvent(this.eventqueue);
    }
}
export class EventQueue implements IEventQueue{
    public q: any[];
    constructor(){
        this.q = [];
    }
}
export interface ITrackEvent{
    event(a: EventType, b: ITeaLogMetadata): void;
}
export class TrackEvent implements ITrackEvent{
    private eventqueue: IEventQueue;
    constructor(eventqueue: IEventQueue){
        this.eventqueue = eventqueue;
    }
    public event(a:EventType, b: ITeaLogMetadata | any): void{
        try {
            console.debug("Pushing trackEvent call on to Q");
            var c = "";
            Object.keys(b).forEach((key: string) => {
                c += "&" + key + "=" + b[key];
            });            
            this.eventqueue.q.push({a,b});
       } catch (e) {
            console.error("Unable to create cdc.ut.trackEvent.event function:", e);
       }
    }
}

export type TeaLogConfigType = {
    logging?: {
        level?: string
    },
    data?: {
        userSessionStorageKey?: string,
        jwtStorageKey?: string,
        nativeTrackingHost?: string,
        user?: TypeUser,
        jwt?: string,
    },
    settings?: {
        hidePII?: boolean,
        allowTestUserTracking?: boolean,
        nativeTracking?: boolean,
        cloudTracking?: boolean
    }
}

// Events/Actions Allowed
export enum EventType {
    contentBrowsing,
    routerNavigation,
    hyperlinkNavigation,
    contentDownload,
    contentSearch,
    scheduleManagement,
    scheduleConflict,
    expressInterest,
    speakerBrowsing,
    sessionBrowsing,
    scheduleBrowsing,
    surveyActivity,
    carouselAction,
    buttonAction,
    promptSubmission,
    promptFeedback,
    recommendationReceipt,
    recommendationManagement,
    badgeScanActivity,
    exceptionOccurrence,
    heartBeat
}

// Object Structure Required
export interface ITeaLogData extends ITeaLogMetadata, ITeaLogUser{
}
export interface ITeaLogMetadata {
}
export interface ITeaLogUser{
    eventId: string;
    userKey: string;
    email: string;
    userFullName: string;
    country: string;
    attendeeType: string;
    jobRole: string;
    jobLevel: string;
    geo: string;
    isTestRecord: boolean;
    accessLevel: number;
    dashboardPackage: string;

    hidePII(): TeaLogUser;
}
export class TeaLogUser implements ITeaLogUser{
    eventId: string;
    userKey: string;
    email: string;
    userFullName: string;
    country: string;
    attendeeType: string;
    isTestRecord: boolean;
    accessLevel: number;
    jobRole: string;
    jobLevel: string;
    geo: string;
    dashboardPackage: string;
    constructor(user: TypeUser){
            if(!user)
                return;
            this.eventId = user.eventId;
            this.userKey = user.userKey;
            this.email = user.email;
            this.userFullName = (user.firstName? user.firstName : "")
                .concat(user.lastName ? " " + user.lastName : "");
            this.country = user.country;
            this.attendeeType = user.attendeeType ? user.attendeeType.join("~") : null;
            this.jobLevel = user.jobLevel;
            if(user.reportClassification){
                this.attendeeType = user.reportClassification;
            }
            this.isTestRecord = user.isTestRecord === 'yes';
            this.dashboardPackage = user.dashboardPackage ? user.dashboardPackage.join("~") : null;
            if(user.impact){
                this.attendeeType = user.impact.attendeeType ? user.impact.attendeeType.join("~") : this.attendeeType;
                this.jobRole = user.impact.jobRole ? user.impact.jobRole : this.jobRole;
                this.geo = user.impact.geoAgenda ? user.impact.geoAgenda : this.geo;
            }
        }

    public hidePII(): TeaLogUser{
        this.email = null;
        this.userFullName = null;
        return this;
    }
}

export interface IRouterNavigationMetadata extends ITeaLogMetadata{
    component: string;
    pageType: string | undefined;
    browsingMilliseconds: number;
}

export interface IContentBrowsingMetadata extends ITeaLogMetadata{
    component: string;
    pageType: string;
    browsingMilliseconds: number;
    origin: string | undefined;
}

export interface IHyperlinkNavigationMetadata extends ITeaLogMetadata{
    linkText: string;
    linkUrl: string;
}

export interface IContentDownloadMetadata extends ITeaLogMetadata{
    contentTitle: string;
    contentUrl: string;
}

export interface IContentSearchMetadata extends ITeaLogMetadata{
    keyword: string;
    dataset: string | undefined;
    filters: string[] | undefined;
}

export interface IScheduleManagementMetadata extends ITeaLogMetadata{
    scheduleManagementOperation: string;
    targetSessionTitle: string | undefined;
    targetSessionType: string | undefined;
    targetSessionTimeId: string | undefined;
    isRecommended: boolean | undefined;
    targetTimeSlotStart: Date | undefined;
    targetTimeSlotEnd: Date | undefined;
    conversationId: string | undefined;
}

export type TypeSession = {
    sessionTimeId: string;
    sessionId: string | undefined;
    sessionTitle: string | undefined;
    sessionType: string | undefined;
}

/** 
* @typedef {"addSession" | "removeSession" | "downloadSessionCalendar" | "syncSessionCalendar" | "invitationAcceptance" | "invitationRejection" | "schedulePersonalTime" | "unschedulePersonalTime" | "leaveTimeSlotOpen"} ScheduleManagementOperation
*/
export enum ScheduleManagementOperation {
    addSession, removeSession, downloadSessionCalendar, syncSessionCalendar, 
    invitationAcceptance, invitationRejection, schedulePersonalTime, unschedulePersonalTime, leaveTimeSlotOpen
} 

export interface IScheduleConflictMetadata extends ITeaLogMetadata{
    scheduleConflictDecision: string;
    previouslyScheduledSessionTitle: string;
    previouslyScheduledSessionTimeId: string | undefined;
    newlyChosenSessionTitle: string;
    newlyChosenSessionTimeId: string | undefined;
}

export enum ScheduleConflictOperation {
    scheduleNewSession, keepOldSession
} 

export type TypeInterest = {
    sessionTimeId: string | undefined;
    sessionId: string | undefined;
    exhibitorID: string | undefined;
    type: InterestType;
    title: string | undefined;
    name: string | undefined;
    abbreviation: string;
}

/** 
* @typedef {"favorite" | "unfavorite"} InterestOperation
*/
export enum InterestOperation {
    favorite, unfavorite
}

export interface IInterestMetadata extends ITeaLogMetadata {
    interestOperation: string;
    interestId: string;
    interestType: string;
    interestAbbreviation: string;
    interestTitle: string;
    isRecommended: boolean | undefined,
    conversationId: string | undefined
}

/** 
* @typedef {"sessionInterest" | "exhibitorInterest" | "demoInterest"} InterestType
*/
export enum InterestType {
    sessionInterest, exhibitorInterest, demoInterest
}

export interface ISessionBrowsingMetadata extends ITeaLogMetadata {
    browsingMilliseconds: number;
    targetSessionTitle: string;
    targetSessionType: string | undefined;
    targetSessionTimeId: string | undefined;
}

export interface ISpeakerBrowsingMetadata extends ITeaLogMetadata {
    browsingMilliseconds: number;
    targetSpeakerName: string;
    targetSpeakerId: string | undefined;
}

export interface IMyScheduleBrowsingMetadata extends ITeaLogMetadata {
    browsingMilliseconds: number;
    scheduleSection: string | undefined;
}

export enum ScheduleSection {
    mySchedule, aux, exec1On1, 
}

export interface ISurveyActivityMetadata extends ITeaLogMetadata {
    surveyActivityOperation: string;
    browsingMilliseconds: number;
    surveyedSessionTitle: string;
    surveyedSessionId: string;
}

/** 
* @typedef {"knowledgeAnswerInput" | "contentRatingInput" | "speakerRatingInput" | "saveForLater" | "submission"} SurveyActivityOperation
*/
export enum SurveyActivityOperation {
    knowledgeAnswerInput, contentRatingInput, speakerRatingInput, saveForLater, submission
} 

export interface ICarouselActivityMetadata extends ITeaLogMetadata {
    carouselActivityOperation: string;
    browsingMilliseconds: number;
}

/** 
* @typedef {"dismissCard" | "browseCard"} CarouselActivityOperation
*/
export enum CarouselActivityOperation {
    dismissCard, browseCard, 
} 

export interface IButtonActivityMetadata extends ITeaLogMetadata {
    operation: string;
}

export interface IPromptSubmissionMetadata extends ITeaLogMetadata {
    prompt: string;
    conversationId: string | undefined;
    exchangeId: string | undefined;
}

export interface IPromptFeedbackMetadata extends ITeaLogMetadata {
    response: string;
    positive: boolean;
    comment: string | undefined;
    conversationId: string;
}

export interface IRecommendationReceiptMetadata extends ITeaLogMetadata {
    conversationId: string | undefined;
    exchangeId: string | undefined;
    recommendationId: string;
    recommendationType: string;
    recommendationScore: number;
    recommendationAbbreviation: string;
    recommendationTitle: string;
}

/** 
* @typedef {"session" | "exhibitor" | "demos"} RecommendationType
*/
export enum RecommendationType {
    session, exhibitor, demos
}

export type TypeRecommendation = {
    sessionTimeId: string | undefined;
    sessionId: string | undefined;
    exhibitorId: string | undefined;
    title: string | undefined;
    source: string | undefined;
    name: string | undefined;
    abbreviation: string;
    recommendationScore: number;
}

export interface IRecommendationManagementMetadata extends ITeaLogMetadata{
    recommendationManagementOperation: string;
    targetRecommendationId: string;
    targetRecommendationType: string;
    targetRecommendationAbbreviation: string;
    targetRecommendationTitle: string;
}

/** 
* @typedef {"rejectRecommendation" | "enrollIntoRecommendedSession" | "favoriteRecommendation" | "rollBackToPreviousRecommendation" | "expressPositiveFeedBack" | "expressNegativeFeedback"} RecommendationManagementOperation
*/
export enum RecommendationManagementOperation {
    rejectRecommendation, enrollIntoRecommendedSession, favoriteRecommendation, rollBackToPreviousRecommendation, expressPositiveFeedBack, expressNegativeFeedback
}

export interface IBadgeScanMetadata extends ITeaLogMetadata{
    badgeId: string;
    location: string;
}

export interface IExceptionOccurrence extends ITeaLogMetadata{
    errorCode: string;
    errorMessage: string;
    errorType: string;
    impact: string | undefined;
    origin: string | undefined;
}

export interface IHeartBeatMetadata extends ITeaLogMetadata{
    message: string;
    origin: string | undefined;
}

export interface IOktaJWT {
    ver: number;
    jti: string;
    iss: string;
    aud: string;
    iat: number;
    exp: number;
    cid: string;
    uid: string;
    scp: string[];
    auth_time: number;
    sub: string;
    tenant_name: string;
    last_name: string;
    preferred_username: string;
    ccoid: string;
    given_name: string;
    access_level: number;
    full_name: string;
    email_address: string;
    federated_id: string;
    azp: string;
    family_name: string;
    first_name: string;
}

export interface IJWTToken{
    access_token: string;
    scope: string;
    validUntil: Date;
}
export class JWTToken implements IJWTToken{
    access_token: string;
    scope: string;
    validUntil: Date;
    constructor(
        token: IJWTToken){
            this.access_token = token.access_token;
            this.scope = token.scope;
            this.validUntil = token.validUntil;
    }
    refreshRequired = function (minutes: number) {
        return new Date(this.validUntil).getTime() - new Date().getTime() <= minutes * 60 * 1000;
    }
    isExpired = function () {
        return this.refreshRequired(0);
    }
}
import React, { useState, useEffect } from 'react';

import screensaverPopup1 from '../../../../assets/images/screensaver/screesaver-1.png';
import screensaverPopup2 from '../../../../assets/images/screensaver/screesaver-2.png';
import screensaverPopup3 from '../../../../assets/images/screensaver/screesaver-3.png';
import screensaverPopup4 from '../../../../assets/images/screensaver/screesaver-4.png';
import { useLocation } from 'react-router-dom';
import { handleReset } from '../main-container/handleResetView';

const Screensaver = ({ inactivityTimeout = 30000, flipInterval = 3000, showPopup, long, lat, zoom, rotation, pitch, bearing, whereAmIBuilding, whereAmILevel ,ciscoSpacesPopupImage, centerLong, centerLat,setShowAICard ,setShowPopupCard}) => {
    const [isActive, setIsActive] = useState(true);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);


    const images = [
        ciscoSpacesPopupImage
        /**
        screensaverPopup1,
        screensaverPopup2,
        screensaverPopup3,
        screensaverPopup4, */
    ];

    let timeoutId;

    const resetTimeout = () => {
        //clearTimeout(timeoutId);
        setIsActive(false);
        // timeoutId = setTimeout(() => setIsActive(true), inactivityTimeout);
    };
    useEffect(() => {
        try {
            setIsActive(true);
            handleReset(
                long,
                lat,
                rotation ? rotation : -4.5,
                zoom ? zoom : 20,
                true, true, false, pitch, bearing, whereAmIBuilding, whereAmILevel,centerLong, centerLat
            )
        } finally {
            if(setShowAICard){
             setShowAICard(false);
            setShowPopupCard(false);
            }
            showPopup(null, null, false);
        }
        
    }, []);
    /** useEffect(() => {
         const handleUserActivity = () => resetTimeout();
 
         window.addEventListener('mousemove', handleUserActivity);
         window.addEventListener('keydown', handleUserActivity);
         window.addEventListener('click', handleUserActivity);
         window.addEventListener('touchstart', handleUserActivity);
 
         resetTimeout(); // Start the timer
 
         return () => {
             clearTimeout(timeoutId);
             window.removeEventListener('mousemove', handleUserActivity);
             window.removeEventListener('keydown', handleUserActivity);
             window.removeEventListener('click', handleUserActivity);
             window.removeEventListener('touchstart', handleUserActivity);
         };
     }, []); */

    useEffect(() => {
        if (isActive) {
            const flipIntervalId = setInterval(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
            }, flipInterval);

            return () => clearInterval(flipIntervalId);
        }
    }, [isActive, flipInterval]);

    return (
        isActive && (
            <div className="screensaver-overlay" onClick={resetTimeout} >
                <div className="screensaver-overlay-popup">
                    <img src={images[currentImageIndex]} alt="Screensaver Content" className="screensaver-overlay-image" />
                </div>
            </div>
        )
    );
};

export default Screensaver;

import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { TeaLogService } from '../tealog.service';
import { IContentSearchMetadata } from '../tealog.model';

/**
 * @whatItDoes Enters a Tealium log record of a navigation event generated by a click of a hyperlink in anchor tag.
 *
 * @howToUse
 * ```
 *    <input type="text" [teaLogContentSearch]="{dataset: 'session data', filters: ['sessionType']}">
 *
 * ```
 */
@Directive({
  selector: '[teaLogContentSearch]'
})
export class TeaLogContentSearchDirective {
  
  private contentSearchMetadata: IContentSearchMetadata | any;
  private timeout: any;
  private waitOutTimeInSeconds: number = 3;

  constructor(
    private teaLogService: TeaLogService, 
    private elementRef: ElementRef<HTMLInputElement>){}
  
  @Input('teaLogContentSearch')
  set metadata(contentSearchMetadata: IContentSearchMetadata) {
    this.contentSearchMetadata = contentSearchMetadata;
  }

  @HostListener('keyup')
  typingEvent() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(
      () => this.teaLogKeywordSearchEvent(),
      this.waitOutTimeInSeconds * 1000);
  }

  private teaLogKeywordSearchEvent(){
    this.teaLogService.teaLogKeywordSearchAction(
      this.elementRef.nativeElement.value,
      this.contentSearchMetadata.dataset,
      this.contentSearchMetadata.filters
    );

  }

  ngOnDestroy(): void {
  }

}